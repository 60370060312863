import React, { useRef, useEffect, Suspense } from "react";
import { motion } from "framer-motion";
import { Navigation } from "./Navigation";
import "./styles.css";
import { useState } from "react";

const Example = ({ isOpen }) => {
  const containerRef = useRef(null)
  const [ContainerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(containerRef.current.offsetWidth);
    }

    handleResize()
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const [windowDimensions, setWindowDimensions] = useState({});

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      setWindowDimensions({
        width,
        height
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const [Offset, setOffset] = useState('85%')

  useEffect(() => {
    const diff = parseInt(windowDimensions.width - ContainerWidth)
    const half = diff / 2
    const padOffset = (windowDimensions.width >= 991.98) ? 62 : 50 
    const final = parseInt(ContainerWidth + half - padOffset)
    setOffset(final + 'px')
    
  }, [ContainerWidth, windowDimensions.width])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll-y");
    } else {
      document.body.classList.remove("no-scroll-y");
    }

  }, [isOpen]);

  return (
    <div className="body container-md px-4 px-lg-5" ref={containerRef}>
      <Suspense>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        className={isOpen ? "nav" : ""}
      >

        <motion.div className="background2" variants={
          {
            open: {
              clipPath: `polygon(-3700px -3700px, 6000px -3700px, 6000px 6000px, -3700px 6000px)`,
              transition: {
                duration: 1.6,
                delay: 0.52,

                ease: "easeInOut"
              },
            },
            closed: {
              clipPath: `polygon(${Offset} 40px, ${Offset} 40px, ${Offset} 40px, ${Offset} 40px)`,
              transition: {
                duration: 0.5,
                delay: 0.4,
                ease: "easeOut",
              },
            },
          }
        }>
          <motion.div className="background3" variants={
            {
              open: {
                clipPath: `polygon(-3700px -3700px, 6000px -3700px, 6000px 6000px, -3700px 6000px)`,
                transition: {
                  duration: 1.6,
                  delay: 0.6,
                  ease: "easeInOut"
                },
              },
              closed: {
                clipPath: `polygon(${Offset} 40px, ${Offset} 40px, ${Offset} 40px, ${Offset} 40px)`,
                transition: {
                  duration: 0.5,
                  delay: 0.3,

                  ease: "easeOut"
                },
              },
            }
          } >
            <Navigation isOpen={isOpen} />
          </motion.div>
        </motion.div>

      </motion.nav>
      </Suspense>

    </div>
  );
};

export default Example;
