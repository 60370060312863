import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import UnderConstruction from './UnderConstruction';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import firebase from 'firebase/compat/app';


const root = ReactDOM.createRoot(document.getElementById('root'));
// if (process.env.NODE_ENV === "production")
// console.log = function no_console() {};

// const firebaseConfig = {
//   apiKey: "AIzaSyBY1qb29Okd3XBrH0-eKjCCpeb7UNwF-AE",
//   authDomain: "swissma-ad1d5.firebaseapp.com",
//   projectId: "swissma-ad1d5",
//   storageBucket: "swissma-ad1d5.appspot.com",
//   messagingSenderId: "639698704793",
//   appId: "1:639698704793:web:da1ff354b2588890335b08"
// };


root.render(
  <React.StrictMode>
    <App />
    {/* <UnderConstruction /> */}
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// firebase.initializeApp(firebaseConfig)
