const DataofTabs = [

  {
    id: 2,
    title: "Aj Travel",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010288/newPortfolio/image_8_eku4jl.png",
    images: [
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010287/newPortfolio/0-02-05-b47e6960ad87f49d57d39839c260d4c1ed30249bb5cf80a43662a032a69fefb0_1c6db7133a8f2d_hcuida.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-a0a4372f56a3b663a251d344300360078ddbe459bd4195e4ce7036b1b90312b0_1c6db7133a9e8a_gl8kco.png",
    "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185829/kutiza/portfolio/ajtravel/img3_ojvcpi.png",
    "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185830/kutiza/portfolio/ajtravel/img7_qalpd8.png",
    "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185830/kutiza/portfolio/ajtravel/img5_s6oa28.png",
    "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185830/kutiza/portfolio/ajtravel/img4_s83fic.png",
    "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185829/kutiza/portfolio/ajtravel/img2_iws7ud.png",
    ]

  },
  {
    id: 3,
    title: "Mr Fisher",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-e69c62b340af0fd5fc81be9feb371b4af32fea92b526f54344e611cb152074a5_1c6db7133b5f84_m0d7rx.png",
    images: [
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-e69c62b340af0fd5fc81be9feb371b4af32fea92b526f54344e611cb152074a5_1c6db7133b5f84_m0d7rx.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185843/kutiza/portfolio/mrfisher/img1_zihuke.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185843/kutiza/portfolio/mrfisher/img2_lqdbth.png",
      ""
    ]
  },
  {
    id: 4,
    title: "Focus Invest",
    category: "Social Media",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-9f2581b3f5b50f2d77ccf06f59fa4be3a0b724c38e18c28a464c47d41dd22d7a_1c6db7133b6c13_koqho0.png",
    images: [
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010290/newPortfolio/0-02-05-32f31a2789ebc529466763eb9aee1341543ebee7fb4296dab2392f4fec4f4064_1c6db7133adf24_b6vxlv.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-9f2581b3f5b50f2d77ccf06f59fa4be3a0b724c38e18c28a464c47d41dd22d7a_1c6db7133b6c13_koqho0.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185842/kutiza/portfolio/focus/img2_qpqmku.png",
    ]
  },
  {
    id: 5,
    title: "Delta Security",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],

    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-9ddd1ee3300536c969866c3bf60a0840ad039a3604d31eefc4fe97f1b7ee455c_1c6db148dea089_lxhane.png",
    images: [
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-9ddd1ee3300536c969866c3bf60a0840ad039a3604d31eefc4fe97f1b7ee455c_1c6db148dea089_lxhane.png",


    ]},
 
  {
    id: 6,
    title: "megaSun",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-7f7b3907916f8a150987d028615a4a7da1148d33956c4242175afa159d467444_1c6db71339d3d7_bkbwix.png",
    images:[ 
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-7f7b3907916f8a150987d028615a4a7da1148d33956c4242175afa159d467444_1c6db71339d3d7_bkbwix.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185842/kutiza/portfolio/megasun/image1_jlhkcj.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185842/kutiza/portfolio/megasun/image3_vsrd27.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185842/kutiza/portfolio/megasun/image2_hqwidc.png"
    ]},
  {
    id: 7,
    title: "Diamond Hotel",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010287/newPortfolio/0-02-05-a27a2dd985c496df3d6df96956ff9fd395f6217af733e718935ddfef6e10e083_1c6db7133ac91e_dj2zt3.png",
    images:[ "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010287/newPortfolio/0-02-05-a27a2dd985c496df3d6df96956ff9fd395f6217af733e718935ddfef6e10e083_1c6db7133ac91e_dj2zt3.png",
  ]},
  {
    id: 8,
    title: "City Grill",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010287/newPortfolio/Frame_2609367_bgxauj.png",
    images:[ "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010287/newPortfolio/Frame_2609367_bgxauj.png",
  ]},
 
  {
    id: 9,
    title: "Let's Go Gym",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010288/newPortfolio/0-02-05-d4fbcc5a1a4492e3e39b541011691b4cfd916e6258faa8066b300313d1da28c3_1c6db71339a07d_adunt1.png",
    images:[
       "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010288/newPortfolio/0-02-05-d4fbcc5a1a4492e3e39b541011691b4cfd916e6258faa8066b300313d1da28c3_1c6db71339a07d_adunt1.png",
       "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010288/newPortfolio/0-02-05-4ea21cd35a75ed86429b2ccd66be80e817609fcb8b7f00ed2f978aac2b9dfea2_1c6db71339c8a8_xalsiv.png",
      //  "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413975/Portfolio/11img_dijmfc.jpg",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185844/kutiza/portfolio/letsgogym/image1_eabktw.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185843/kutiza/portfolio/letsgogym/image2_bowpbz.png",

  ]},
  {
    id: 10,
    title: "Nürnberg Döner",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010290/newPortfolio/0-02-05-32256298449ba51f6ab304fc9c8f16debffa52acf24e5f3c5709be8c9d930668_1c6db71338c9d8_xtbm0i.png",
    images:
    [ 
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010290/newPortfolio/0-02-05-32256298449ba51f6ab304fc9c8f16debffa52acf24e5f3c5709be8c9d930668_1c6db71338c9d8_xtbm0i.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185841/kutiza/portfolio/doner/img9_jpgf8q.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185841/kutiza/portfolio/doner/img6_mgf2rw.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185841/kutiza/portfolio/doner/img3_azwj9z.png",
      "https://res.cloudinary.com/dr5bzig6k/image/upload/v1687185840/kutiza/portfolio/doner/img1_zkf2im.png",
      
    ]},
  {
    id: 11,
    title: "Tjegulla",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413976/Portfolio/16img_xh5ysk.jpg",
    images:
    [
     "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413976/Portfolio/16img_xh5ysk.jpg",
  ]},
  {
    id: 12,
    title: "Twist",
    category: "Web Development",
    problem: "Problem Statement",
    solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
    description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
    challanges: [

      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

    ],
    img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413982/Portfolio/14img_f2my8w.png",
    images:
    [
 "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413982/Portfolio/14img_f2my8w.png"
  ]},
  // {
  //   id: 14,
  //   title: "Let's Go Gym",
  //   category: "Web Development",
  //   problem: "Problem Statement",
  //   solution: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis amet fames amet amet, nibh fermentum.",
  //   description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Lorem Ipsum is that it has a more-or-less Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. normal distribution of letters, as opposed to using'Content here, content here',",
  //   challanges: [

  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",


  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",

  //   ],
  //   img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010290/newPortfolio/0-02-05-32f31a2789ebc529466763eb9aee1341543ebee7fb4296dab2392f4fec4f4064_1c6db7133adf24_b6vxlv.png",
  // },
  // {
  //   id: 15,
  //   title: "Lorem 14",
  //   category: "Branding",
  //   img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413982/Portfolio/14img_f2my8w.png",

  // },
  // {
  //   id: 16,
  //   title: "Lorem 15",
  //   category: "Social Media",
  //   img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413976/Portfolio/16img_xh5ysk.jpg"

  // },
  // {
  //   id: 17,
  //   title: "Lorem 16",
  //   category: "Social Media",
  //   img: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1665413975/Portfolio/11img_dijmfc.jpg",
  // },
];

export default DataofTabs;