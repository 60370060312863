import React, { useState, useEffect, useRef } from 'react';
import './BlackbarComponent.css';

const NumberCounter = ({ speed, endpoint }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Change this value to adjust when the counting starts
    };

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        const counter = setInterval(() => {
          setCount((prevCount) => {
            const nextCount = prevCount + 1;
            if (nextCount > endpoint) {
              clearInterval(counter);
              return prevCount;
            }
            return nextCount;
          });
        }, speed);
      } else {
        clearInterval(counterRef.current);
      }
    }, observerOptions);

    observer.observe(counterRef.current);

    return () => {
      observer.disconnect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearInterval(counterRef.current);
    };
  }, [speed, endpoint]);

  return <span className="numberofinc" id="stats-number" ref={counterRef}>{count}</span>;
};

export default NumberCounter;
