import React from 'react'
import svg3 from '../Services/imgs/services3.svg'
import './why.css'

function Whychoose() {
    return (
        <div className="container">
            <div className='row gy-5 gy-lg-0 align-content-center mx-auto' >
                <div className='col-12 col-lg  '>
                    <img className='img-fluid' src={svg3} alt='' />
                </div>
                <div className='col-12 col-lg my-lg-auto justift-content-end'>
                    <div className='text-start'>
                        <span className=" titleofputting   mb-3 ">Putting Your <span className="subtitleofputting   ms-1" style={{padding:'5px 15px !important'}}> Success</span> First </span>
                        <p className=' subheadingtitle  text-start mt-2 '>At Kutiza, we are dedicated to putting your success at the forefront of everything we do. Our client-centric approach is designed to ensure that your goals, objectives, and vision remain at the center of our strategies and solutions.</p>
                        <div className='borderus ' >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whychoose