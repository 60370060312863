import React from 'react'
import AgencyUs from '../newAbout/AgencyUs'
import Value from '../newAbout/Value'
import '../newAbout/AgencyUs.css'
import '../newAbout/Value.css'
import '../About/aboutus.css'
import Stories from "../newAbout/Stories.js"
import Team from "../newAbout/Team.js"
import "../newAbout/HireUs.css"
import BlackbarComponent from '../newAbout/BlackbarComponent'
import Whyus from "../newAbout/Whyus.js"
import "../newAbout/Whyus.css"
import LandingPageContact from '../LandingPage/LandingPageContact.js'
import { Link } from 'react-router-dom'
function AboutUs() {
    return (
        <div>
            <div className="containerofspace ">
                <AgencyUs />
                <Value />
                <Whyus />
            </div>
            <div className='containerofspace'>
                <BlackbarComponent />
                <Stories />
                <div id='team' className='containerofspace'>
                    <Team />
                    <div className='pt-5'>
                        <Link to={'/team'} className='viewAllBtn ' style={{border: '1px solid #000'}}>
                            View All
                        </Link>
                    </div>
                </div>
            </div>
            <div className=''>
                <LandingPageContact />
            </div>
            <div className=""></div>
        </div>

    )
}

export default AboutUs