import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";

const Card = ({ item, handleClick }) => {
  return (
    <div className="container containercard my-5 mx-0 py-0 px-0">
      <div className="galleryofportfolio row">
        {item.map((Val) => (
          <div
            className="cardofdata col-12 col-md-6 col-lg-4 my-auto placeholder-glow " style={{}}
            key={Val.id}
            onClick={() => handleClick(Val)}
          >
            <div className="containerofphoto">
              <Link className="image-container" to={`/portfolio/${Val.id}`}>
                <img
                  src={Val.img}
                  alt={Val.title}
                  className="photo gallery__img lazy"
                  style={{ display: "block", width: "100%", height: "auto" }}
                />
                <div className="overlay">
                  <p className="texttt">{Val.title}</p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
