import './Contactus.css'
import React, { useState } from "react";
import { useEffect } from 'react';
import { useLocation } from 'react-router';

function Contactus() {

    const [IsInContact, setIsInContact] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/contactus') {
            setIsInContact(true)
        }
        else {
            setIsInContact(false)

        }
    }, [location])

    return (
        <>
            <div style={{ background: '#000' }}>

                <div className="container-md px-4 px-lg-5">
                    <div className={`containerofspace ${IsInContact && "contactBlackClass"}`}>
                        <div className=' navbarGap'>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-12 col-md my-auto'>
                                        <div className='d-flex flex-column '>
                                            <p className="thisiscontacttitle contacttitle text-start ps-0">Ready for Digital Excellence? </p>
                                            <p className='contactheadingtitle text-start ps-0'>Contact Us and Begin the Journey</p>
                                            <p className="thisiscontactsubtitle contactsubtitle text-start ps-0 pt-3" >
                                                Welcome to Kutiza, where we specialize in transforming your digital presence and bringing your vision to life. Whether you're a small startup or a well-established brand, we have the skills and passion to create captivating online experiences that drive real results.
                                                Let us help you shine online, whether you need a stunning website, improved search engine visibility, or social media management. With our talented team and cutting-edge technology, we're ready to deliver outstanding outcomes for your business.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-xl-6'>
                                        <div className='thisiscontactmaindiv py-xl-5'>
                                            <div className='h-100 pt-3 d-flex flex-column justify-content-xl-between'>
                                                <div>
                                                    <div className=''>
                                                        <div className="pb-3">
                                                            <input type="text" style={{ backgroundColor: 'black', color: '#fff', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700' }} className="form-control formcontact  py-3" id="exampleFormControlInput1" placeholder="NAME" />
                                                        </div>
                                                        <div className="pb-3">
                                                            <input type="text" style={{ backgroundColor: 'black', color: '#fff', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700' }} className="form-control formcontact  py-3" id="exampleFormControlInput1" placeholder="SURNAME" />
                                                        </div>
                                                        <div className="pb-3">
                                                            <input type="text" style={{ backgroundColor: 'black', color: '#fff', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700' }} className="form-control formcontact py-3" id="exampleFormControlInput1" placeholder="EMAIL" />
                                                        </div>
                                                        <div className="pb-3">
                                                            <input type="text" style={{ backgroundColor: 'black', color: '#fff', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700' }} className="form-control formcontact  py-3" id="exampleFormControlInput1" placeholder="I'M INTERESTED IN..." />
                                                        </div>
                                                        <div>
                                                            <textarea style={{ textarea: 'none', backgroundColor: 'black', color: '#fff', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700' }} rows="5" cols="" className='form-control formcontact' placeholder="COMMENT"></textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='' style={{ bottom: 0 }}>
                                                    <div className='mt-3 mt-xl-0'>
                                                        <button type="button " className="buttonoflanding fontofbuttonss px-5  contactBtn py-2">SUBMIT</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerofspace"></div>
                </div>
            </div>

        </>

    )
}

export default Contactus
