import './App.css';
import AboutUs from './Views/AboutUs.js';
import OriginalPortfolio from './Views/OriginalPortfolio.js';
import LandingView from './Views/LandingView.js';
import Services from './Views/Services.js';
import Impressum from './Views/Impressum.js';
import Login from './Views/Login.js';
import Contactus from './Components/Contactus.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navi from "./Navi.js"
import Footer2 from './Footer2.js'
import React from 'react'
import DetailofPortfolio from './Portfolio/Tabs/DetailofPortfolio'
import ScrollToTop from './ScrollToTop.js'
import Example from "./NavBar/Example.js"
import './observers.js'
import { useCycle } from "framer-motion";
import FullTeam from './Views/FullTeam';
import MovingSquares from './Components/MovingSquares'
import ServiceDetails from './Portfolio/Tabs/ServiceDetails'
import PartnerDetails from './LandingPage/PartnerDetails'


function App() {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (

    <div className="App">
      <MovingSquares />
      <BrowserRouter >
        <ScrollToTop>
          <Navi isOpen={isOpen} toggleOpen={toggleOpen} />
          <Example isOpen={isOpen} />
          <Routes>
            <Route exact path="/" element={<LandingView />} />
            <Route exact path="/about" element={<AboutUs />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/team" element={<FullTeam />} />
            <Route exact path="/contactus" element={<Contactus />} />
            <Route exact path="/services" forceRefresh={true} element={<Services />} />
            <Route exact path="/impresum" forceRefresh={true} element={<Impressum />} />
            <Route path="/portfolio" element={<OriginalPortfolio />} />
            <Route path="/portfolio/:id" element={<DetailofPortfolio />} />
            <Route path="/services/:services" element={<ServiceDetails />} />
            <Route path="/portfolio/:id" element={<PartnerDetails />} />
          </Routes>
          <Footer2 />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );

}

export default App;
