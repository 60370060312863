import React from 'react'
import Tabs from '../Portfolio/Tabs/Tabs.js'
function OriginalPortfolio() {
  return (
    <div className='' style={{marginTop: '200px' }}>
        <Tabs />
    </div>
  )
}

export default OriginalPortfolio