import React from "react";
import { motion } from "framer-motion";
import "./styles.css";
// import { useMediaQuery } from "react-responsive";

const Path = (props) => (
  <motion.path fill="#000" strokeWidth="3" strokeLinecap="round" {...props} />
);

const MenuToggle = ({ toggle, isOpen }) => {

  return (
    <div className="">
      <button className=" navbarOpenBtn" onClick={toggle}>
        <motion.div
          style={{display: 'grid', placeItems: 'center', width: '50px', height: '50px'}}
          >
          <motion.div
            initial={{width: '50px', height: '50px'}}
            style={{ background: '#000', display: 'grid', placeItems: 'center' }}
            variants={
              {
                open: {
                  width: '0px',
                  height: '0px',
                  transition: {
                    duration: 0.4,
                    ease: "easeInOut",
                    delay: 0.2,

                  },
                },
                closed: {
                  width: '50px',
                  height: '50px',
                  transition: {
                    duration: 0.2,
                    ease: "easeInOut",
                    delay: 1.2,

                  },
                },
              }
            }
            animate={isOpen ? "open" : "closed"}

          >
            <motion.svg width="23" height="23" viewBox="0 0 23 23"
              animate={isOpen ? "open" : "closed"}
              variants={
                {
                  open: {
                    opacity: 0,
                    transition: {
                      delay: 0.1,
                      ease: "easeInOut",

                    },
                  },
                  closed: {
                    opacity: 1,
                    transition: {
                      delay: 1.7,
                      ease: "easeOut",
                    },
                  },
                }
              }
            >
              <>
                <Path
                  stroke="#fff"
                  fill="#fff"
                  strokeLinecap="round"
                  strokeWidth='1'
                  strokeLinejoin="round"
                  variants={{
                    closed: { stroke: "#fff", d: "M1.34766 3.31055L21.9187 1.00023" },
                    open: { stroke: "#fff", d: "M 5 18.5 L 19 4.5" },
                  }}
                />
                <Path
                  d="M21.918 12.6211L1.3469 10.3108"
                  stroke="#000"
                  fill="#000"
                  strokeLinecap="round"
                  strokeWidth='1'
                  strokeLinejoin="round"
                  variants={{
                    closed: { stroke: "#fff", opacity: 1 },
                    open: { stroke: "#fff", opacity: 0 },
                  }}
                  transition={{ duration: 0.1 }}
                />
                <Path
                  stroke="#fff"
                  fill="#fff"
                  strokeLinecap="round"
                  strokeWidth='1'
                  strokeLinejoin="round"
                  variants={{
                    closed: { stroke: "#fff", d: "M21.918 21.9316L1.3469 19.6213" },
                    open: { stroke: "#fff", d: "M 5 4.5 L 19 18.346" },
                  }}
                />
              </>
            </motion.svg>
          </motion.div>


          <motion.div
            initial={false}
            style={{ background: '#000', display: 'grid', placeItems: 'center', overflow: 'hidden' }}
            variants={
              {
                open: {
                  width: '50px',
                  height: '50px',
                  transition: {
                    duration: 0.4,
                    ease: "easeInOut",
                    delay: 1.4,


                  },
                },
                closed: {
                  width: '0px',
                  height: '0px',
                  transition: {
                    duration: 0.4,
                    ease: "easeOut",
                    delay: 0.3,

                  },
                },
              }
            }
            animate={isOpen ? "open" : "closed"}

          >
            <motion.svg width="23" height="23" viewBox="0 0 23 23"
              animate={isOpen ? "open" : "closed"}
              variants={
                {
                  open: {
                    opacity: 1,
                    transition: {
                      delay: 2.2,
                      ease: "easeInOut",

                    },
                  },
                  closed: {
                    opacity: 0,
                    transition: {
                      delay: 0.1,
                      ease: "easeOut",
                    },
                  },
                }
              }
            >
              <>
                <Path
                  stroke="#fff"
                  fill="#fff"
                  strokeLinecap="round"
                  strokeWidth='1'
                  strokeLinejoin="round"
                  variants={{
                    closed: { stroke: "#fff", d: "M1.34766 3.31055L21.9187 1.00023" },
                    open: { stroke: "#fff", d: "M 5 18.5 L 19 4.5" },
                  }}
                />
                <Path
                  d="M21.918 12.6211L1.3469 10.3108"
                  stroke="#000"
                  fill="#000"
                  strokeLinecap="round"
                  strokeWidth='1'
                  strokeLinejoin="round"
                  variants={{
                    closed: { stroke: "#fff", opacity: 1 },
                    open: { stroke: "#fff", opacity: 0 },
                  }}
                  transition={{ duration: 0.1 }}
                />
                <Path
                  stroke="#fff"
                  fill="#fff"
                  strokeLinecap="round"
                  strokeWidth='1'
                  strokeLinejoin="round"
                  variants={{
                    closed: { stroke: "#fff", d: "M21.918 21.9316L1.3469 19.6213" },
                    open: { stroke: "#fff", d: "M 5 4.5 L 19 18.346" },
                  }}
                />
              </>
            </motion.svg>
          </motion.div>
        </motion.div>
      </button>
    </div>
  );
};

export default MenuToggle;
