import React from 'react'
// import SketchComponent from '../Components/SketchComponent'
// import FlagEffect from '../Components/FlagEffect'
function Impressum() {
    return (
        <div className='py-5 my-5'>
            <div className='container'>
                <p><strong><i>Kontakt-Adresse</i></strong></p>
                <p>Swissma GmbH<br></br>Fällmisstrasse 12<br></br>8832 Wilen b. Wollerau<br></br>Schweiz</p>
                <p>E-Mail:<br></br>info@swissma.ch</p>
                <br></br>
                <br></br>
                <p><strong><i>Vertretungsberechtigte Person(en)</i></strong></p>
                <p>Samir Menasria, Geschäftsinhaber Swissma GmbH</p>
                <br></br>
                <br></br>
                <p><strong><i>Handelsregister-Eintrag</i></strong></p>
                <p>Eingetragener Firmenname:<br></br><strong>Swissma GmbH</strong></p>
                <p>Unternehmens-Nr (UID):<br></br><strong>CHE-215.063.772</strong></p>
                <br></br>
                <p>Mehrwertsteuer-Nummer<br></br><strong>CHE-215.063.772 MWST</strong></p>
                <br></br>
                <br></br>
                <p><strong><i>Haftungsausschluss</i></strong></p>
                <p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.</p>
                <p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.</p>
                <p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne besondere Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
                <br></br>
                <br></br>
                <p><strong><i>Haftungsausschluss für Links</i></strong></p>
                <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers.</p>
                <br></br>
                <br></br>
                <p><strong><i>Urheberrechte</i></strong></p>
                <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website, gehören ausschliesslich <strong>der Firma Swissma GmbH</strong> oder den speziell genannten Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.</p>
                <br></br>
                <p>ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung, welche in jedem Fall geahndet wird.</p>
                <p>Quelle: <a href="https://www.swissanwalt.ch" target="_blank" rel="noreferrer">SwissAnwalt</a></p>
                Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!
            </div>
            <div className='container'>
                {/* <FlagEffect /> */}
                {/* <SketchComponent /> */}
                
            </div>
        </div>
    )
}

export default Impressum