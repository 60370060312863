import React, {Suspense} from 'react'
import '../LandingPage/HeroLanding.css'
import LandingComponent from '../LandingPage/LandingComponent.js'
import LandingPageContact from '../LandingPage/LandingPageContact'
// import Liquid from "../LandingPage/Liquid.js"
// import Scroller from '../LandingPage/Scroller.js'
import Team from '../newAbout/Team'
import { Link } from 'react-router-dom'
import LandingPortfolio2 from '../LandingPage/LandingPortfolio2'
import Companions from '../LandingPage/Companions'
// import NewsFeed from '../LandingPage/NewsFeed'

const Testcomponent = React.lazy(() => import('../LandingPage/Testcomponent'));
function LandingView() {
    return (
        <div className="mainhero">
            <div className=" landingcomponent">
                <LandingComponent />
            </div>
            <div className="aboutcomponent paddingbetweencomponents ">
                {/* <Liquid /> */}
            </div>
            <div className=' '>
                <Suspense>

                <Testcomponent />
                </Suspense>
            </div>
            {/* <div className='paddingbetweencomponents thisshowsinmobile mb-5 pb-5 pb-xl-0 mb-xl-5'>
                <Scroller />
            </div> */}
            <div className='paddingbetweencomponents'>
                    <LandingPortfolio2 />
                </div>
            <div className='paddingbetweencomponents'>
                    <Companions />
                </div>
            <div className='blackWrapper'>
                
                <div className='paddingbetweencomponents containerofspace bottompaddingg'>
                    <Team />
                    <div className='pt-5'>
                        <Link to={'/team'} className='text-center'>
                            <button className='buttonoflanding fontofbuttonss px-5 blackButtonLanding m-auto' style={{ backgroundColor: "black"}}>
                                View All
                            </button>    
                        </Link>
                    </div>
                </div>
                {/* <div className='paddingbetweencomponents containerofspace pb-5'>
                    <AboutusComponent />
                </div> */}
            </div>
            <div className=''>
                <LandingPageContact />
            </div>
            <div className="containerofspace"></div>
        </div>
    )
}

export default LandingView