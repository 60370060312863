import React from "react";
import DataofTabs from "./DataofTabs";
import "./Buttons.css"
const Buttons = ({ filterItem, setItem, menuItems }) => {

  return (
    <>
      <div className="d-flex justify-content-center py-5 flex-wrap" >
        <div className="btn-group scrollonsmallscreens" style={{flexWrap: 'wrap'}}>
          <button
            className="btn-white text-dark p-1 px-4 mx-5 btn active checked fw-bold" 
            id="buttonoftabs"
            onClick={() => setItem(DataofTabs)}
          >
            All
          </button>
          {menuItems.map((Val, id) => {
            return (
              <button
                className="btn-white text-dark p-1 px-4 px-lg-4 mx-lg-5 btn fw-bold" 
                id="buttonoftabs"
                onClick={() => filterItem(Val)}
                key={id}
              >
                {Val}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Buttons;