import React from "react";
import { useParams } from "react-router-dom";
import ServiceData from "./ServiceData";
import "./Services.css"
function ServiceDetails() {

    const { services } = useParams();
    const itemId = parseInt(services);
    const item = ServiceData.find((item) => item.id === itemId);

    return (
        <div className="selectioncolor" style={{ background: 'black' }}>
            <div className='container-md px-4 px-lg-5 pb-5'>
                <div className=" pt-5 ">
                    <div className="     gx-4 gx-lg-5">
                        <div className="  containerofspace order-2 order-sm-1">
                            <div className="maintittleee servicesTitle pt-0 text-start" style={{ color: "black", paddingBottom: "2rem" }}>
                                <span className='maintittleee selectioncolor' style={{ color: '#fff' }}>{item.category}</span>
                            </div>
                            <div className="text-start " style={{ color: '#fff' }}>
                                <p className="subtitleoflandport mb-0 selectioncolor">
                                    {item.description}
                                </p>
                                <div className="col text-center order-1 order-sm-2 pt-5 pt-sm-2">
                                    <img style={{ maxHeight: "510px", maxWidth: "100%" }} src={item.image2} alt="" className="" />
                                </div>
                                <div className="mt-3 row">
                                    {item.services && item.services.map((service, index) => (
                                        <div className="col-12 col-md-6" key={index}>
                                            <h4 className="titleoflandport selectioncolor" style={{ color: '#fff' }}>{service.title}</h4>
                                            <p className="subtitleoflandport selectioncolor" style={{ color: '#fff' }}>{service.content}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-3 " style={{ color: '#fff' }}>
                                    <p className="subtitleoflandport mb-0 selectioncolor"> {item.conclusion}
                                    </p> </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceDetails;
