import React from 'react'
// import logoadmin from './img.svg'
// import { useContext, useState } from "react"
// import { signInWithEmailAndPassword } from "firebase/auth";
// // import { auth } from "../firebase/credentials.js"
// import { useNavigate } from "react-router-dom";

const Login = () => {

    // const [error, setError] = useState(false);
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");

    // const navitage = useNavigate()

    // // const { dispatch } = useContext(AuthContext)

    // const handleLogin = (e) => {
    //     e.preventDefault();

    //     signInWithEmailAndPassword(auth, email, password)
    //         .then((userCredential) => {
    //             // Signed in
    //             const user = userCredential.user;
    //             // dispatch({ type: "LOGIN", payload: user })
    //             navitage("/")
    //         })
    //         .catch((error) => {
    //             setError(true);
    //         });
    // }
    return (
        <div className="" style={{ height: '80vh', alignItems: 'center' }}>
            {/* <div className="container py-5 my-5 justify-content-center" stlye={{ justifyContent: 'center', alignItems: 'center' }}>
                <form onSubmit={handleLogin}>
                    <div className="row" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                        <div className="col-5">
                            <div className="row">
                                <div className="col-5 align-self-end">
                                    <p className="maintitleofcategory py-4">Admin log in</p>
                                </div>
                                <div className="col-4   ">
                                    <img src={logoadmin} />
                                </div>
                            </div>
                        </div> 
                        <div className="col-5 mb-3 py-3">
                            <label for="exampleFormControlInput1" className="form-label ">Email</label>
                            <input type="email" className="form-control py-2" id="exampleFormControlInput1" style={{borderRadius: '8px', fontWeight: '400', fontSize: '16px'}} placeholder="Email" onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="col-5 mb-3 py-3">
                            <label for="exampleFormControlInput1" className="form-label ">Password</label>
                            <input type="password" className="form-control py-2" id="exampleFormControlInput1" style={{borderRadius: '8px'}} placeholder="Password" onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="align-content-center">
                            <button type="submit" className="btn btn-primary px-5" id="buttonfinishh">Login</button>
                        </div>
                        <div className="py-4"> {error && <span>Wrong email or password!</span>} </div>
                    </div>
                </form>
            </div> */}
        </div>
    )
}

export default Login