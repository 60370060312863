import React from 'react'
import TopServices from '../Services/topServices.js'
import OurServices from '../Services/ourServices.js'
import ScrollCircle from '../Services/ScrollCircle.js'

import Whychoose from '../Services/Whychoose.js'
import LandingPageContact from '../LandingPage/LandingPageContact.js'
import Capabilities from '../Services/Capabilities.js'

function Services() {
  return (
    <>
      <div className="containerofspace ">
        <div className="pb-3 pb-md-5"><TopServices /> </div>
        <div className="py-3 py-md-5"><OurServices /> </div>
        <div className='py-3 py-md-5'> <Capabilities /> </div>
        <div className="containerofspace"><ScrollCircle /> </div>
        <div className="containerofspace"><Whychoose /> </div>
      </div>
      <div className=""><LandingPageContact /> </div>
    </>


  )
}

export default Services