import './service.css'
import svg1 from '../Services/imgs/services1.svg'
function topServices() {
    return (
        <div className="container">
            <div className='row align-content-center mx-auto socialcontainer' >
                <div className='col-12 col-lg my-auto justift-content-end'>
                    <p className='servicestoptitle text-xl-start mt-md-3 mt-5 pt-4 pt-md-0'>Creative Solutions that Inspire</p>
                    <p className=' subheadingtitle text-xl-start  socialheadingstyle' >Our creative services are designed to captivate your audience and leave a lasting impression. From striking visual designs to compelling storytelling, we create memorable brand experiences that connect with your target market. Our creative solutions encompass logo design, brand identity development, graphic design, and more.</p>
                </div>
                <div className='col-12 col-lg  text-xl-start'>
                    <img style={{maxHeight: "403px"}} className='img-fluid' src={svg1} alt='' />
                </div>

            </div>
        </div>
    )
}

export default topServices