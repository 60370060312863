import React, { useEffect, useState } from 'react'
import "../newAbout/Team.css"
import LandingPageContact from '../LandingPage/LandingPageContact.js'
import Lorik from '../images/Lorik.png'
import Denis from '../images/Denis.png'
import Bleron from '../images/Bleron.png'

function FullTeam() {
  const [itemData, setItemData] = useState([]);
  useEffect(() => {
    // Fetch or update the item data here
    // For demonstration purposes, I'll initialize the data directly
    const itemData = [
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1688391284/kutiza/Team/Gresa_tktn4g.svg",
        position: "UI UX",
        colorposition: "#E92269",
        name: "Gresa Bytyqi",
        fullposition: "UI/UX Designer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1695632750/kutiza/Team/Group_427319548_rs55rs.png",
        position: "Design",
        colorposition: "#35A0DC",
        name: "Dion Arifi",
        fullposition: "Graphic Designer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1695632750/kutiza/Team/Group_427319549_zrsnok.png",
        position: "Front-End",
        colorposition: "#00CF98",
        name: "Mergim Murati",
        fullposition: "Front End Developer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1688391285/kutiza/Team/Diellza_n43t56.svg",
        position: "IOS Dev",
        colorposition: "#E92269",
        name: "Diellza Hoxha",
        fullposition: "iOS Developer",
      },
      {
        image: Lorik,
        position: "Back-End",
        colorposition: "#35A0DC",
        name: "Lorik Aliu",
        fullposition: "Back-End Developer",
      },
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708617628/Rinesa_1_1_keaxvn.png",
        position: "Front-End",
        colorposition: "#F7D34E",
        name: "Rinesa Grabovci",
        fullposition: "Front End Developer",
      },
    
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708615409/Group_427319632_uibnll.png",
        position: "Front-End",
        colorposition: "#35A0DC",
        name: "Flori Frrokaj",
        fullposition: "Front End Developer",
      },
      {
        image: Denis,
        position: "Design",
        colorposition: "#00CF98",
        name: "Denis Demolli",
        fullposition: "Social Media Manager",
      },
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708615401/Group_427319634_1_zh8lpj.png",
        position: "UI UX",
        colorposition: "#E92269",
        name: "Era Fazliu",
        fullposition: "UI/UX Designer",
      },
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708615399/Group_427319635_ijeay2.png",
        position: "Marketing",
        colorposition: "#35A0DC",
        name: "Drin Arifi",
        fullposition: "Digital Marketing Lead",
      },
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708615401/Group_427319636_sof7xw.png",
        position: "Back-End",
        colorposition: "#6665DD",
        name: "Bleron Maxhuni",
        fullposition: "Back-End Developer",
      },
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708615399/Group_427319638_xizb4o.png",
        position: "Back-End",
        colorposition: "#F7D34E",
        name: "Albenit Bytyqi",
        fullposition: "Back-End Developer",
      },
   
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708615399/Group_427319637_vnrttd.png",
        position: "Front-End",
        colorposition: "#6665DD",
        name: "Shpend Rrustemi",
        fullposition: "Front End Developer ",
      },

      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708616229/DriniHasani_moyixl.png",
        position: "UI UX",
        colorposition: "#00CF98",
        name: "Drin Hasani",
        fullposition: "UI/UX Designer",
      },
    
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708615399/Group_427319639_xtqcaa.png",
        position: "Front-End",
        colorposition: "#E92269",
        name: "Ardit Zogjani",
        fullposition: "Front End Developer ",
      },
    ];
    setItemData(itemData)


  }, []);
  return (
    <>
      <div className='container-md px-4 px-lg-5'>
        <div className="containerofspace ">
          <div className="maintittleee servicesTitle pt-5 pt-md-0" style={{ color: "black", paddingBottom: "2rem" }}>
            <span className='maintittleee pt-0'> Meet Our Team Members</span>
          </div>
          <div className="row g-4 g-md-3 g-xxl-4 justify-content-center">

            {itemData.map((item) => (
              <div className="col-12 col-sm-6 col-lg-4 col-xxl-3" key={item.image}>
                <div className="teamswiper" style={{ position: 'relative' }}>
                  <div className="Figure">
                    <div className="teamMemberInfo" style={{ background: item.colorposition }}>{item.position}</div>
                    <img
                      className=" w-100"
                      src={item.image}
                      alt="Team Member"
                    />
                    <div className=" teamMemberInfo2">
                      <div className="theorderof2">
                        <p className="nameofteammember" style={{ color: item.colorposition }}>{item.name}</p>
                        <div className="" style={{ display: "flex" }}>
                          <p className="positionofteammember mb-0" style={{ fontWeight: "700" }}>{item.fullposition}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className="containerofspace"></div>
      </div>
      <div className=''>
        <LandingPageContact />
      </div>
    </>
  )
}

export default FullTeam