import React, { useState } from "react";
import { motion } from "framer-motion";
// import { NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
      delay: 1,
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    setActiveAccordion(id === activeAccordion ? null : id);
  };

  const items = [
    {
      id: 1,
      item: "Home",
      urlFunc: true,
      url: "/",
    },
    {
      id: 2,
      item: "About us",
      // subitems: [
      //   { id: 2, subitem: "Our Story", url: "/about#ourstory" },
      //   { id: 1, subitem: "Our Team", url: "/about#ourteam" },
      //   { id: 3, subitem: "Our Clients", url: "/about" },
      // ],
      urlFunc: true,
      url: "/about",
    },
    {
      id: 3,
      item: "Services",
      // subitems: [
      //   { id: 1, subitem: "Subitem 1", url: "https://example.com/subitem1" },
      //   { id: 2, subitem: "Subitem 2", url: "https://example.com/subitem2" },
      // ],
      urlFunc: true,
      url: "/services",
    },
    {
      id: 4,
      item: "Contact",
      // subitems: [
      //   { id: 1, subitem: "Subitem 1", url: "https://example.com/subitem1" },
      //   { id: 2, subitem: "Subitem 2", url: "https://example.com/subitem2" },
      // ],
      urlFunc: true,
      url: "/contactus",
    },
  ];

  return (
    <>
      <div className="disableselection">
        {items.map((item) => (
          <motion.li
            variants={variants}
            whileTap={{ scale: 0.95 }}
            key={item.id}
          >
            {/* <a href={item.urlFunc ? item.url : undefined}>
              <div className="">
                <span
                  className="d-flex flex-column my-0 my-md-3 titleofmenu"
                  style={{ color: "white", whiteSpace: "nowrap" }}
                  onClick={() => toggleAccordion(item.id)}
                >
                  {item.item}
                </span>
                {activeAccordion === item.id && (
                  <>
                    {item.subitems.map((subitem) => (
                      <div className="d-flex flex-column" key={subitem.id}>
                        <NavHashLink
                          smooth
                          to={subitem.url}
                          className="subtitleofmenu pt-2"
                          style={{ color: "white" }}
                        >
                          {subitem.subitem}
                        </NavHashLink>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </a> */}
            <Link to={item.url}>
              <span
                className="d-flex flex-column my-0 my-md-3 titleofmenu"
                style={{ color: "white", whiteSpace: "nowrap" }}
                onClick={() => toggleAccordion(item.id)}
              >
                {item.item}
              </span>
            </Link>
          </motion.li>
        ))}
      </div>
    </>
  );
};
