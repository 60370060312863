
export const itemsdata = [
    {
        id: 1,
        maintitle: "Our Portfolio",
        sidetitle: "Too Cool for Templates"
    },
    {
        id: 2,
        title: "Aj Travel",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010288/newPortfolio/image_8_eku4jl.png",
    },
    {
        id: 3,
        title: "Mr.Fisher",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-e69c62b340af0fd5fc81be9feb371b4af32fea92b526f54344e611cb152074a5_1c6db7133b5f84_m0d7rx.png",
    },
    {
        id: 4,
        title: "Focus Invest",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-9f2581b3f5b50f2d77ccf06f59fa4be3a0b724c38e18c28a464c47d41dd22d7a_1c6db7133b6c13_koqho0.png",
    },
    {
        id: 5,
        title: "Delta Security",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-9ddd1ee3300536c969866c3bf60a0840ad039a3604d31eefc4fe97f1b7ee455c_1c6db148dea089_lxhane.png",
    },
    // {
    //     id: 5,
    //     title: "Aj Travel",
    //     subtitle: "BrandBook",
    //     image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-a0a4372f56a3b663a251d344300360078ddbe459bd4195e4ce7036b1b90312b0_1c6db7133a9e8a_gl8kco.png",
    //     display: "none"
    // },
    {
        id: 6,
        title: "Mega Sun",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010289/newPortfolio/0-02-05-7f7b3907916f8a150987d028615a4a7da1148d33956c4242175afa159d467444_1c6db71339d3d7_bkbwix.png",
    },
    {
        id: 7,
        title: "Diamond Hotel",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010287/newPortfolio/0-02-05-a27a2dd985c496df3d6df96956ff9fd395f6217af733e718935ddfef6e10e083_1c6db7133ac91e_dj2zt3.png",
    },
    {
        id: 8,
        title: "City Grill",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010287/newPortfolio/Frame_2609367_bgxauj.png",
    },
    {
        id: 9,
        title: "Let's Go Gym",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010288/newPortfolio/0-02-05-d4fbcc5a1a4492e3e39b541011691b4cfd916e6258faa8066b300313d1da28c3_1c6db71339a07d_adunt1.png",
    },
    {
        id: 10,
        title: "Nürnberg Döner",
        subtitle: "BrandBook",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694010290/newPortfolio/0-02-05-32256298449ba51f6ab304fc9c8f16debffa52acf24e5f3c5709be8c9d930668_1c6db71338c9d8_xtbm0i.png",
    }

    

]

