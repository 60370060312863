const ServiceData = [

  {
    id: 1,
    category: "Branding Services",
    image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692276076/Group_427319474_m6yubn.png",
    description: "At Kutiza, we understand that a strong and memorable brand identity is the backbone of your business. Our team of creative experts is passionate about helping you craft a unique brand that stands out in the market and resonates with your target audience. We offer a comprehensive suite of branding services tailored to meet your specific needs and objectives.",
    services: [
      {
        title: "Logo Design",
        content: "Your logo is the visual representation of your brand. Our talented designers will work closely with you to create a captivating logo that encapsulates your brand's essence and leaves a lasting impression on your customers."
      },
      {
        title: "Brandbook",
        content: "A brandbook is a vital tool for maintaining brand consistency across all touchpoints. We will create a comprehensive guide that outlines your brand's visual identity, including logo usage, colour palette, typography, and more."
      },
      {
        title: "Website",
        content: "Your website is often the first point of contact with your audience. Our expert team will design and develop a stunning, user-friendly website that showcases your brand's personality and effectively communicates your message."
      },
      {
        title: "Brand Voice",
        content: "Crafting a distinct and authentic brand voice is essential for connecting with your audience. We will help you define your brand's tone, messaging, and communication style to build a strong emotional connection with your customers."
      },
      {
        title: "Brand Strategy",
        content: "A well-defined brand strategy sets the course for your brand's success. Our strategists will conduct in-depth market research and competitor analysis to develop a strategic roadmap that positions your brand effectively and drives growth."
      },
      {
        title: "Brand Guidelines",
        content: "Consistency is key to building a strong brand presence. Our brand guidelines will serve as a reference for your team, ensuring all marketing materials, campaigns, and communications align with your brand identity."
      }
    ],
    conclusion: "Whether you are launching a new brand or looking to revamp your existing one, our branding services are designed to elevate your brand to new heights. Let's work together to create a brand that leaves a lasting impact on your audience."
  },
  
  {
    id: 2,
    image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692276076/Group_427319481_xt8gpi.png",
    category: "Content Creation Services",
    description: "Content is the backbone of your digital presence, and at Kutiza, we specialise in creating compelling and engaging content that captivates your audience and drives results. Our team of content creators, writers, and video experts work seamlessly to deliver content that resonates with your target audience across various platforms.",
    services: [
      {
        title: "Omni-channel Content Creation",
        content: "Our omni-channel approach ensures that your brand's message reaches your audience on the platforms they frequent the most. From social media to your website and beyond, we create cohesive content that enhances your brand's visibility."
      },
      {
        title: "Short-form Video Creation",
        content: "In today's fast-paced digital landscape, short-form videos have become a powerful way to convey your message. We produce impactful and visually stunning short videos that grab attention and drive engagement."
      },
      {
        title: "Influencer Marketing",
        content: "Partnering with influencers can significantly expand your brand's reach and credibility. Our team will identify the right influencers for your niche and develop authentic partnerships that amplify your brand's message."
      },
      {
        title: "Long-form Video Creation",
        content: "Tell your brand's story in greater detail with long-form videos. Our creative team will produce compelling narratives that showcase your brand's values and connect with your audience on a deeper level."
      },
      {
        title: "Copywriting & Video Script",
        content: "Words have the power to inspire action. Our expert copywriters will craft persuasive copy and compelling video scripts that drive conversions and effectively communicate your brand's value proposition."
      }
    ],
    conclusion: "Whether you need attention-grabbing social media content or engaging long-form videos, we have the expertise to deliver content that sparks interest and drives results. Let's create content that connects with your audience and boosts your brand's presence."
  },
  
  {
    id: 3,
    image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692276076/Group_427319482_tp085b.png",
    category: "Marketing Services",
    description: "With the ever-evolving digital landscape, marketing is both an art and a science. At Kutiza, our marketing experts are masters at navigating this dynamic environment to drive measurable results for your brand. We combine creativity, data-driven insights, and cutting-edge tools to deliver successful marketing campaigns.",
    services: [
      {
        title: "Social Media Marketing",
        content: "Engage and grow your audience on various social media platforms with our tailored social media marketing strategies. We create compelling content, run targeted ad campaigns, and foster meaningful interactions to build a loyal community around your brand."
      },
      {
        title: "Search Engine Marketing",
        content: "Boost your online visibility and attract qualified leads with our effective search engine marketing (SEM) services. Our team will optimize your paid search campaigns to deliver maximum ROI and drive relevant traffic to your website."
      },
      {
        title: "Search Engine Optimization",
        content: "Organic visibility is essential for sustainable growth. We employ data-driven SEO strategies to improve your website's ranking on search engines, ensuring your brand is easily discoverable by your target audience."
      },
      {
        title: "Programmatic Advertising",
        content: "Reach the right audience at the right time with programmatic advertising. Our experts leverage automated technologies to optimize ad placements and targeting, ensuring your ads are seen by those most likely to convert."
      },
      {
        title: "PPC Advertising",
        content: "Our pay-per-click (PPC) advertising campaigns are designed to drive immediate results. From keyword research to ad creation and optimization, we will help you maximize your ad spend and achieve your marketing objectives."
      }
    ],
    conclusion: "Whether you're looking to build brand awareness, drive website traffic, or generate leads, our marketing services are tailored to meet your specific goals. Let's create data-driven strategies that propel your brand forward."
  },
  
  {
    id: 4,
    category: "Development Services",
    image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692276076/Group_427319488_ufeoy8.png",
    description: "In a digital-first world, having a strong online presence is non-negotiable. At Kutiza, our development services are crafted to provide you with innovative, scalable, and user-friendly solutions that leave a lasting impact on your audience.",
    services: [
        {
            title: "Saas",
            content: "Software as a Service (Saas) solutions offer unparalleled accessibility and flexibility. We design and develop Saas applications that cater to your unique business needs, ensuring smooth operations and customer satisfaction."
        },
        {
            title: "Web & Ecommerce",
            content: "Your website and online store are your virtual storefronts. We create visually stunning, responsive websites and user-friendly ecommerce platforms that deliver seamless shopping experiences to your customers."
        },
        {
            title: "Digital Products",
            content: "Transform your innovative ideas into fully functional digital products. Our development team will work closely with you to understand your vision and build digital solutions that captivate your target audience."
        },
        {
            title: "App Development",
            content: "In the mobile-driven era, having a compelling mobile app is a game-changer. We develop intuitive and feature-rich mobile applications that enable you to engage with your customers wherever they go."
        },
        {
            title: "Software Development",
            content: "Every business has unique requirements. Our custom software development services are tailored to your specific needs, ensuring that your software solutions optimize your business processes and workflows."
        }
    ],
    conclusion: "Whether you need a robust web platform, a cutting-edge mobile app, or custom software, our development services are geared to deliver innovation and enhance your digital presence."
},
  {
    id: 5,
    category: "Consulting Services",
    image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692276076/Group_427319489_gdveeb.png",
    description: "In today's rapidly changing business landscape, making informed decisions is critical for success. At Kutiza, our experienced consultants offer data-driven insights and expert guidance to help your brand thrive in the digital realm.",
    services: [
        {
            title: "Growth Strategy",
            content: "Achieving sustainable growth requires a well-planned strategy. Our consultants will analyze market trends, consumer behavior, and your brand's strengths to develop a growth roadmap that sets you on the path to success."
        },
        {
            title: "Digitalization",
            content: "Embracing digital transformation is essential for staying competitive in the modern business landscape. Our consultants will guide you through the process of digitalization, identifying opportunities to streamline operations, improve customer experiences, and leverage emerging technologies to gain a competitive edge."
        },
        {
            title: "Brand",
            content: "Your brand's perception in the market can make or break your success. Our consultants will conduct a thorough brand analysis, identify areas for improvement, and develop strategies to enhance your brand's positioning and resonance among your target audience."
        },
        {
            title: "Digital Trends",
            content: "Staying ahead of digital trends is crucial to remaining relevant and adaptive. Our experts continuously monitor the digital landscape, keeping you informed about the latest trends, technologies, and opportunities that align with your brand's objectives."
        },
        {
            title: "Professional Analysis & Reporting",
            content: "Data-driven decision-making is at the core of our consulting services. We provide comprehensive analysis and reporting, giving you valuable insights into your performance, customer behavior, and marketing effectiveness. With this knowledge, you can make well-informed decisions that drive your business forward."
        }
    ],
    conclusion: "Whether you need guidance on growth strategies, digitalization, brand positioning, or understanding the latest digital trends, our consulting services are designed to empower your brand with the knowledge and strategies to succeed in the ever-changing digital world."
}

];

export default ServiceData;