import React from 'react'
import './ScrollCircle.css'
import { useEffect, useState } from 'react';
 
const circleData = [
    {
        id: "1",
        title: "Meeting",
        subtitle: "We start by getting to know you and your business. In an initial meeting, we dive deep into your goals, challenges, and vision. This allows us to understand your unique needs and craft a tailored approach for your digital success.",
        img: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1688997731/1_ux9ani.svg",
    },
    {
        id: "2",
        title: "Research & Audit",
        subtitle: "Next, we conduct in-depth research and audit your current digital presence. We analyze your target market, competitors, and industry trends to uncover valuable insights that will shape our strategies moving forward.",
        img: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1688997787/2_kqkjc9.svg",
    },
    {
        id: "3",
        title: "Strategy & Creative",
        subtitle: "Based on our research, we develop a robust digital strategy tailored to your specific objectives. Our team of experts brings together creativity and strategic thinking to devise captivating solutions that will engage your audience and drive results.",
        img: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1688997822/3_b8dhob.svg",
    },
    {
        id: "4",
        title: "Excecution",
        subtitle: "Once the strategy is in place, we put our expertise to work. From web design and development to content creation, SEO optimization, and more, we execute every aspect of your digital project with meticulous attention to detail and technical precision. ",
        img: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1688997847/4_iif1al.svg",
    },
    {
        id: "5",
        title: "Report & Analyze",
        subtitle: "We believe in data-driven decision-making. Throughout the process, we continuously monitor, measure, and analyze the performance of our strategies. We provide regular reports and insights, allowing you to track progress and make informed decisions.",
        img: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1688997857/5_gwgtlo.svg",
    },
];


function ScrollCircle() {
    // const [lightBox1, setShow] = useState(false)

    // useEffect(() => {

    //     if (lightBox1) {
    //         var lightBox = document.getElementById('outercircle')
    //         var textopacity = document.getElementById('testopacity'),
    //             count = 10;

    //         document.addEventListener('scroll', function (e) {
    //             var offset = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
    //             count = offset * document.documentElement.scrollTop -750;

    //             if (count < 0) {
    //                 count = 0;
    //             }

    //             lightBox.style.width = count + 'px';
    //             lightBox.style.height = count + 'px';
    //             textopacity.style.opacity = 1950 - count;
    //         });
    //     }

    //     else {
    //     }
    //     // }
    //     // scrollEffect();

    // }, [lightBox1]);
    const [lightBox1, setShow] = useState(false);

    useEffect(() => {
        if (lightBox1) {
            const lightBox = document.getElementById('outercircle');
            const textopacity = document.getElementById('testopacity');
        
            const handleScroll = function (e) {
                const offset = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        
                let subtractValue;
                let opacityValue = 1950;
                const width = window.innerWidth;
        
                // Define breakpoints and adjust subtractValue and opacityValue based on window width
                if (width <= 480) { // Small devices (mobile)
                    subtractValue = -500;
                    opacityValue = 1600;
                } 
                else if (width <= 568){
                    subtractValue = -950;
                    opacityValue = 1550;
                } else if (width <= 768) { // Medium devices (tablets)
                    subtractValue = -950;
                    opacityValue = 2150;
                } else if (width <= 1024) { // Large devices (desktops)
                    subtractValue = -700;
                    opacityValue = 1900;
                } else { // Extra large devices (large desktops)
                    subtractValue = -350;
                    opacityValue = 1950;
                }
        
                let count = offset * document.documentElement.scrollTop + subtractValue - 100;
                if (count < 0) {
                    count = 0;
                }
        
                lightBox.style.width = count + 'px';
                lightBox.style.height = count + 'px';
                textopacity.style.opacity = opacityValue - count;
            };
        
            document.addEventListener('scroll', handleScroll);
        
            return () => {
                // Cleanup
                document.removeEventListener('scroll', handleScroll);
            };
        }
    }, [lightBox1]);
    
    
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {

            setScreenWidth(window.innerWidth);

        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    const controlScroll = () => {
        var navbarr = document.getElementById("outercircle");
        var sticky = navbarr.offsetTop;

        if (window.scrollY >= sticky) {

            setShow(true)
            navbarr.style.position = "sticky";
            navbarr.style.position = "-webkit-sticky";
        } else {
            setShow(false)
            navbarr.style.position = "relative";
            navbarr.style.position = "initial";

        }
    }
    useEffect(() => {
        window.addEventListener('scroll', controlScroll)
        return () => {
            window.removeEventListener('scroll', controlScroll)
        }
    }, [])
    return (
        <section id="lightBox">
            <div className="stickydiv">
                <div className=" rreshtijoneee">
                    <div className="titleofcardsss">
                        {(screenWidth > 768) && (
                            <p className="maintitleofservices"
                            >Our Work Process</p>
                        )}
                        {(screenWidth <= 768) && (
                            <p className="maintitleofservices" id="testopacity"
                            >Our Work Process</p>
                        )}
                    </div>
                </div>
                <div className="circlesection">
                    <div className="outercircle" id={`${lightBox1}` && "outercircle"}>
                        <div className="innercircle">
                            <div className="container containerofcardsofcircle ">
                                {circleData.map((service, id) => {
                                    return (
                                        <div className="cardofcircle" key={id}>
                                            <img className="photoofcard" src={service.img} alt='imageofcard' />
                                            <p className="titleoffirstcard m-0">
                                                {service.title}
                                            </p>
                                            <p className="listoffirstcard m-0">
                                                {service.subtitle}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default ScrollCircle
