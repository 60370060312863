import React, { useState } from "react";
import DataofTabs from "./DataofTabs";
import Card from "./Card";
import Buttons from "./Buttons";

const Tabs = () => {
  const [item, setItem] = useState(DataofTabs);
  const [isLoading, setIsLoading] = useState(false);  // Step 1

  const menuItems = [...new Set(DataofTabs.map((Val) => Val.category))];

  const filterItem = (curcat) => {
    setIsLoading(true);  // Step 2
    const newItem = DataofTabs.filter((newVal) => {
      return newVal.category === curcat;
    });
    setItem(newItem);
    setIsLoading(false);  // Step 3
  };

  const handleClick = (card) => {
    console.log("Clicked card:", card);
  };

  return (
    <div className="container containeroftabs">
      <div className="row">
        <h1 className="col-12 text-center my-3 fw-bold">Something from our work</h1>
        <Buttons
          filterItem={filterItem}
          setItem={setItem}
          menuItems={menuItems}
          style={{ fontSize: "25px" }}
        />
        {/* Step 4 */}
        {isLoading ? (
          <div style={{}}>Loading...</div>
        ) : (
          <Card item={item} className="cardofdata" handleClick={handleClick} />
        )}
      </div>
    </div>
  );
};

export default Tabs;
