import React, { useState,useEffect } from 'react'
import "./Landingpagecontact.css"
import { useLocation } from 'react-router'



function LandingPageContact() {
    const location = useLocation()
    const [IsWhite, setIsWhite] = useState(true)
    useEffect(() => {
      if(location.pathname === '/') {
        setIsWhite(true)
      }
      else {
        setIsWhite(false)
      }
    }, [location])
    
    return (
        <div className={`containerofspace ${!IsWhite && "blackContactUs"}`}>
            <div className='landingcontactmaindiv '>
                <div className='container-md px-4 px-lg-5'>
                    <div className='row'>
                        <div className='col-12 col-md my-auto'>
                            <div className='d-flex flex-column px-2 px-sm-0'>
                                <p className="thisiscontacttitle text-start ">Ready for Digital Excellence? </p>
                                <p className='text-start thisissubcontacttitle '>Contact Us and Begin the Journey</p>
                                <p className="thisiscontactsubtitle text-start pt-1 pt-md-3" >
                                Ready to elevate your digital presence and achieve remarkable success? Fill out the form below, and our team of dedicated experts will be in touch with you shortly. Let's embark on this exciting digital journey together and unlock your true potential in the online world.
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='thisiscontactmaindiv py-5'>
                                <div className='px-4 h-100 pt-3 d-flex flex-column justify-content-between'>
                                    <div>
                                        <p className='contactustitle'>Contact Us</p>
                                        <div className='mt-4'>
                                            <div className="pb-3">
                                                <input type="text" style={{ backgroundColor: 'black', color: 'white', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700' }} className="form-control formcontact  py-3" id="exampleFormControlInput1" placeholder="Name" />
                                            </div>
                                            <div className="pb-3">
                                                <input type="text" style={{ backgroundColor: 'black', color: 'white', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700' }} className="form-control formcontact py-3" id="exampleFormControlInput1" placeholder="Email" />
                                            </div>
                                            <div>
                                                <textarea style={{ backgroundColor: 'black', color: 'white', fontSize: '14px', fontFamily: 'Cabinet Grotesk', fontWeight: '700'}} rows="5" cols="" className='form-control formcontact' placeholder="Message"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='' style={{ bottom: 0 }}>
                                        <div>
                                            <button style={{background: IsWhite ? '#fff' : '#000', color: !IsWhite ? '#fff' : '#000'}} type="button " className={`buttonoflanding fontofbuttonss px-5 contactBtn py-2`}>SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPageContact