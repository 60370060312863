import React from 'react'
import BlackBar from "./BlackBar"
import "./BlackbarComponent.css"
function BlackbarComponent() {
    return (
        <div className='py-5 blackWrapper'>
            <div className='container-md'>
                <div className='row justify-content-lg-between justify-content-center'>
                    <div className='col-12 col-sm-6 col-lg-auto '>
                        <div className=' mx-4 mx-md-0 mt-2 mt-md-0  d-flex  flex-column pb-4 pb-lg-0'>
                            <div className='lh-1'>
                                <BlackBar speed={100} endpoint={40} />
                                <span className='numberofinc'>
                                    +
                                </span>
                            </div>
                            <div>
                                <span className="titleofnumberss">
                                    Happy Clients
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-sm-6 col-lg-auto '>
                        <div className=' mx-4 mx-md-0 mt-2 mt-md-0  pb-4 pb-lg-0'>
                            <div  className='lh-1'>
                                <BlackBar speed={50} endpoint={176} />
                                <span className='numberofinc'>
                                    +
                                </span>
                            </div>
                            <div>
                                <span className="titleofnumberss">
                                    Projects Completed
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-sm-6 col-lg-auto '>
                        <div className=' mx-4 mx-md-0 mt-2 mt-md-0  pb-4 pb-lg-0'>
                            <div className='lh-1'>
                                <BlackBar speed={100} endpoint={22} />
                            </div>
                            <div>
                                <span className="titleofnumberss">
                                    Dedicated Members
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-sm-6 col-lg-auto '>
                        <div className=' mx-4 mx-md-0 mt-2 mt-md-0  pb-4 pb-lg-0'>
                            <div className='lh-1'>
                                <BlackBar speed={100} endpoint={34} />
                                <span className='numberofinc'>
                                    +
                                </span>
                            </div>
                            <div >
                                <span className="titleofnumberss">
                                    Reviews
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlackbarComponent