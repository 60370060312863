import React from 'react';
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn } from 'cdbreact';
// import logo from './Components/photos/navbar.svg';
import { Link } from "react-router-dom";
import './footer1.css'
function Footer2() {

    return (
        <CDBFooter className="shadow my-lg-5 gapoffooter" style={{ backgroundColor: '#000' }}>
            <div className="container-md px-4 px-lg-5">

                <CDBBox display="flex" flex="column" className="mx-auto py-5">
                    <CDBBox display="flex" justifyContent="between" className="flex-wrap footerdiv1">
                        <CDBBox className="box1offooter">
                            <a href="/" className="d-flex  text-light justify-content-start mt-3">
                                <img alt="logo" src="https://res.cloudinary.com/dr5bzig6k/image/upload/v1687772876/kutiza/svgviewer-output_13_hz1x25.svg" />

                                {/* <span className="ml-3 h5 font-weight-bold">Devwares</span> */}
                            </a>
                            <div className='displaythisonmoblie'>
                                <p className="mt-3 mb-0 " style={{ maxWidth: '250px', color: '#D8D8D8', fontWeight: '400', textAlign: 'start', fontSize: "18px" }}>
                                    Full Service
                                </p>
                                <p className="mb-3 " style={{ maxWidth: '250px', color: '#D8D8D8', fontWeight: '400', textAlign: 'start', fontSize: "18px" }}>
                                    Digital Agency
                                </p>
                                <p className='mt-3 mb-0' style={{ maxWidth: '250px', color: '#D8D8D8', fontWeight: '400', textAlign: 'start', fontSize: "14px" }}>
                                    Bajram Kelmendi St.,
                                </p>
                                <p className='mb-3' style={{ maxWidth: '250px', color: '#D8D8D8', fontWeight: '400', textAlign: 'start', fontSize: "14px" }}>
                                    Prishtine, Kosovo 10000
                                </p>
                            </div>
                            <div className='displaythisondesktop'>
                                <p className="my-3 mb-1 " style={{ maxWidth: '250px', color: '#D8D8D8', fontWeight: '400', textAlign: 'start', fontSize: "18px" }}>
                                    Full Service Digital Agency
                                </p>
                                <span style={{ maxWidth: '250px', color: '#D8D8D8', fontWeight: '400', textAlign: 'start', fontSize: "14px" }}>
                                    Bajram Kelmendi St., Prishtine, Kosovo 10000 </span>
                            </div>
                            <CDBBox display="flex" className="mt-4 justify-content-start my-3 box2offooter">
                                <CDBBtn flat color="dark" style={{ color: '#D8D8D8', backgroundColor: '#000', borderStyle: 'none' }}    >
                                    <a style={{ textDecoration: "none" }} href='https://www.linkedin.com/company/kutiza/?originalSubdomain=fj'>

                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.4502 10.1472C22.6613 10.1472 24.7818 11.0237 26.3453 12.584C27.9087 14.1442 28.7871 16.2603 28.7871 18.4668V28.1729H23.2291V18.4668C23.2291 17.7313 22.9364 17.0259 22.4152 16.5058C21.894 15.9857 21.1872 15.6936 20.4502 15.6936C19.7131 15.6936 19.0063 15.9857 18.4852 16.5058C17.964 17.0259 17.6712 17.7313 17.6712 18.4668V28.1729H12.1133V18.4668C12.1133 16.2603 12.9916 14.1442 14.5551 12.584C16.1186 11.0237 18.2391 10.1472 20.4502 10.1472V10.1472Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.55793 11.5339H1V28.173H6.55793V11.5339Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.77896 7.37326C5.31374 7.37326 6.55793 6.13167 6.55793 4.60008C6.55793 3.0685 5.31374 1.8269 3.77896 1.8269C2.24418 1.8269 1 3.0685 1 4.60008C1 6.13167 2.24418 7.37326 3.77896 7.37326Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </a>
                                </CDBBtn>
                                <CDBBtn flat className="" style={{ color: '#D8D8D8', backgroundColor: '#000', borderStyle: 'none' }}>
                                    <a style={{ textDecoration: "none" }} href='https://www.instagram.com/kutiza_llc/'>
                                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.6293 1.13403H8.73452C4.89757 1.13403 1.78711 4.23802 1.78711 8.06698V21.9329C1.78711 25.7618 4.89757 28.8658 8.73452 28.8658H22.6293C26.4663 28.8658 29.5768 25.7618 29.5768 21.9329V8.06698C29.5768 4.23802 26.4663 1.13403 22.6293 1.13403Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M21.2374 14.1286C21.4089 15.2825 21.2113 16.4611 20.6729 17.4966C20.1345 18.5321 19.2826 19.3718 18.2384 19.8963C17.1941 20.4208 16.0108 20.6034 14.8566 20.418C13.7024 20.2327 12.6362 19.6889 11.8095 18.864C10.9829 18.0391 10.438 16.975 10.2522 15.8233C10.0665 14.6715 10.2495 13.4906 10.775 12.4485C11.3006 11.4065 12.1421 10.5563 13.1798 10.019C14.2174 9.48174 15.3984 9.28463 16.5548 9.45575C17.7344 9.6303 18.8264 10.1788 19.6696 11.0202C20.5128 11.8617 21.0625 12.9514 21.2374 14.1286Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M23.3223 7.37231H23.3369" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </a>
                                </CDBBtn>
                                <CDBBtn flat className="py-2 px-3" style={{ color: '#D8D8D8', backgroundColor: '#000', borderStyle: 'none' }}>
                                    <a style={{ textDecoration: "none" }} href='https://www.facebook.com/kutiza/'>
                                        <svg width="19" height="30" viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.6096 1H13.2368C11.3039 1 9.45024 1.7375 8.08349 3.05025C6.71674 4.36301 5.94891 6.14348 5.94891 8V12.2H1.57617V17.8H5.94891V29H11.7792V17.8H16.152L17.6096 12.2H11.7792V8C11.7792 7.6287 11.9328 7.2726 12.2062 7.01005C12.4795 6.7475 12.8502 6.6 13.2368 6.6H17.6096V1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </a>
                                </CDBBtn>
                            </CDBBox>
                        </CDBBox>
                        <CDBBox className="box3offooter">
                            <p className="h5 my-4 fontfamilybely" style={{
                                fontWeight: '600', color: 'white', textAlign: 'start'
                            }}>
                                Legal info
                            </p>
                            <CDBBox flex="column" style={{ cursor: 'pointer', padding: '0', textAlign: 'start' }}>
                                <CDBFooterLink href="/"> <p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>Data protection </p></CDBFooterLink>
                                <CDBFooterLink href="/"> <p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>Legal notice </p></CDBFooterLink>

                            </CDBBox>
                        </CDBBox>
                        <CDBBox className="box4offooter" style={{ justifyContent: 'center' }}>
                            <p className="h5 my-4 fontfamilybely" style={{ fontWeight: '600', color: 'white', textAlign: 'start' }}>
                                Products
                            </p>
                            <CDBBox flex="column" style={{ cursor: 'pointer', padding: '0', textAlign: 'start' }}>

                                <Link to="/about" className=''><p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>About Us</p></Link>
                                <Link to="/services" className=''><p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>Services</p></Link>
                                <Link to="/contactus" className=''><p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>Contact</p></Link>
                            </CDBBox>
                        </CDBBox>
                        <CDBBox className="box5offooter">
                            <p className="h5 my-4 fontfamilybely" style={{ fontWeight: '600', color: 'white' }}>
                                Products
                            </p>
                            <CDBBox flex="column" style={{ cursor: 'pointer', padding: '0', textAlign: 'start' }}>
                                <CDBFooterLink href="/"><p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>Windframe </p></CDBFooterLink>
                                <CDBFooterLink href="/"><p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>Loop </p></CDBFooterLink>
                                <CDBFooterLink href="/"><p style={{
                                    fontWeight: '400',
                                    fontSize: "14px", color: '#D8D8D8', marginBottom: '7px'
                                }}>Contrast </p></CDBFooterLink>
                            </CDBBox>
                        </CDBBox>
                    </CDBBox>
                    <small className="text-center" style={{
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '16px',
                        textAlign: 'center',
                        color: '#D8D8D8',
                    }}>
                        Copyright 2023 - kutiza.com
                    </small>
                </CDBBox>
            </div>

        </CDBFooter>
    )
};
export default Footer2