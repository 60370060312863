import React from 'react'
import img1 from '../images/about/img1.svg'
function AgencyUs() {
  return (
    <div className='container-md px-4 px-lg-5 navbarGap' id="ourstory">
      <div className='row'>
        <div className='col-12 col-md-5'>
          <div className='text-center text-md-start mt-5 mt-md-0'>
            <span className='titleofagency'>
              Welcome to <br /> Kutiza
            </span>
            <div className='d-md-flex buttongroup pt-3 pt-md-5' >
              <div>
                <button className='buttonofabout fontofbuttonss px-5'>About Us</button>
              </div>
              <div className='px-md-3 pt-2 pt-md-0'>
                <button className='buttonoftalk fontofbuttonss px-5' type="">Let's Talk</button>
              </div>
            </div>

          </div>
        </div>
        <div className='col-md-7 col-12'>
          <div className='text-start mt-3 ps-0 ps-md-5 ps-lg-0'>
            <span className='subtitleofagency'>
            At Kutiza, we are more than just a digital agency. We are a team of passionate and dedicated individuals who believe in the power of digital transformation. Our mission is to empower businesses like yours with the tools, strategies, and creative solutions needed to thrive in the ever-evolving digital landscape. <br/><br/>
            We take pride in our ability to bring dreams to life. Whether you are a small startup with big aspirations or an established brand looking for a fresh digital perspective, we are here to guide you on your digital journey.
            </span>
          </div>
        </div>

      </div>
      <div className='pt-lg-5 pt-2 mt-2'>
        <img src={img1} alt="" className='img-fluid' />
      </div>
    </div>
  )
}

export default AgencyUs