import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
        var body = document.getElementsByTagName('body')[0]
        var htmlElement = document.getElementsByTagName("html")[0];

        if (location.pathname === '/services') {
            body.style.overflowX = 'initial'
            htmlElement.style.overflowX = 'initial'
        }
        else {
            body.style.overflowX = 'hidden'
            htmlElement.style.overflowX = 'hidden'

        }

    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;