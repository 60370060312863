import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from 'swiper';

// import required modules
function Value() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {

            setScreenWidth(window.innerWidth);

        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    const data = [
        {
            id: 1,
            title: "Innovation",
            subtitle: "We thrive on innovation and embrace the ever-evolving digital landscape. We constantly seek new ideas, technologies, and strategies to push boundaries and deliver cutting-edge solutions. Our team is driven by a relentless pursuit of innovation, ensuring that we stay ahead of the curve and deliver exceptional results for our clients."
        },
        {
            id: 2,
            title: "Collaboration",
            subtitle: "We believe in the power of collaboration. We foster a culture of teamwork, open communication, and shared goals. By working closely with our clients, we build strong partnerships and leverage collective expertise to create digital experiences that truly resonate. Together, we co-create, inspire, and achieve remarkable outcomes.            "
        },
        {
            id: 3,
            title: "Excellence",
            subtitle: "We have an unwavering commitment to excellence in everything we do. From the quality of our work to the level of service we provide, we strive for perfection. We set high standards and constantly challenge ourselves to exceed expectations. With meticulous attention to detail and a passion for delivering excellence, we ensure that every project we undertake is a resounding success.            "
        },
        {
            id: 4,
            title: "Adaptability",
            subtitle: "In the fast-paced digital world, adaptability is key. We embrace change, pivot when needed, and proactively respond to emerging trends and technologies. Our agile mindset allows us to navigate complexities and seize opportunities for our clients. We are quick to adapt our strategies and solutions to ensure that they remain relevant, effective, and future-proof.            "
        },
        {
            id: 5,
            title: "Integrity",
            subtitle: " We uphold the highest standards of integrity and ethical conduct. Honesty, transparency, and trust are at the core of our relationships with clients, partners, and team members. We take pride in being reliable, accountable, and delivering on our promises. Our commitment to integrity ensures that we build long-lasting relationships based on mutual respect and shared values.            "
        },
        {
            id: 6,
            title: "Growth",
            subtitle: "We believe in continuous growth and learning. We foster a culture of curiosity and embrace challenges as opportunities for growth. Our team is dedicated to staying at the forefront of industry trends, expanding our knowledge, and refining our skills. By nurturing a growth mindset, we empower ourselves and our clients to reach new heights and achieve sustainable success."
        },
    ]


    return (

        <div className='container-md px-4 px-lg-5 containerofspace'>

            <div className='buildingsection'>
                <span className='titleofvalue'>
                    Building Success Together: Our Core Values <br />   for   Digital Transformation
                </span>
            </div>
            <div className='mt-4 mb-5 valuetext'>
                <span className='subtitleofvalue ignore-br'>
                    Our core values serve as the foundation of our digital agency, shaping our culture, guiding our actions, and driving our pursuit of excellence. With these values at the forefront, we create a collaborative environment where innovation thrives, integrity is paramount, and growth is nurtured.

                </span>
            </div>
            {(screenWidth <= 991) && (
                <div>

                    <Swiper style={{
                        "--swiper-pagination-color": "#FF5C00",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination": "marginTop:100vh"
                    }}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        modules={[Pagination]}
                        slidesPerView={1}
                        spaceBetween={10}

                        breakpoints={{
                            240: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },

                        }}
                        className="mySwiper"
                    >
                        {data.map(item => {
                            return (
                                <SwiperSlide>
                                    <div className=' px-3 px-lg-5 mb-4 pb-1 pb-sm-0 mb-sm-3' key={item.id}>
                                        <p className='numberofvalues'>
                                            0{item.id}<span style={{ color: "#FF5C00" }}>/</span>
                                        </p>
                                        <p className='titleofvalues' style={{ color: "black" }}> {item.title}</p>
                                        <span className='subtitleofvalues' style={{ color: "black", height: "50px" }}> {item.subtitle}</span>
                                    </div>
                                </SwiperSlide>)
                        })}

                    </Swiper>
                </div>
            )}
            {(screenWidth >= 991) && (
                <div className='text-start'>
                    <div className='row g-5'>
                        {data.map(item => {
                            return (
                                <div className='col-4' key={item.id}>
                                    <p className='numberofvalues'>
                                        0{item.id}<span style={{ color: "#FF5C00" }}>/</span>
                                    </p>
                                    <p className='titleofvalues' style={{ color: "black" }}> {item.title}</p>
                                    <span className='subtitleofvalues' style={{ color: "black", height: "50px" }}> {item.subtitle}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Value