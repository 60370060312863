import { Link } from "react-router-dom";
import "./Capabilities.css"
import React, { useState } from 'react';
const items = [
    {
        id: 1,
        title: "Branding",
        color: "#FFD11F",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690896711/svgviewer-output_30_wysfb7.svg",
        image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690898751/svgviewer-output_31_tufqk3.svg",
        subtitle: [
            "Logo Design",
            "Brandbook",
            "Website",
            "Brand Voice",
            "Brand Strategy",
            "Brand Guidelines"
        ],
        startPoint: "-30%",   // Custom start point for this item
        endPoint: "-0%",     // Custom end point for this item
        hoverOpacity: 1,    // Custom hove
        titleStartPoint: "-10%", // Custom start point for the title
        titleEndPoint: "-0%",
        photo1StartPoint: "-0%",
        photo1EndPoint: "-30%",
        photo2StartPoint: "30%",
        photo2EndPoint: "0%",
        title2StartPoint: "-0%",
        title2EndPoint: "30%"
    },
    {
        id: 2,
        title: "Content Creation",
        color: "#FB820B",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690896712/svgviewer-output_26_b9fbee.svg",
        image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690898751/svgviewer-output_32_j0zcql.svg",
        subtitle: [
            "Logo Design",
            "Brandbook",
            "Website",
            "Brand Voice",
            "Brand Strategy",
            "Brand Guidelines"
        ],
        startPoint: "-30%",   // Custom start point for this item
        endPoint: "-0%",     // Custom end point for this item
        hoverOpacity: 1,    // Custom hove
        titleStartPoint: "-20%", // Custom start point for the title
        titleEndPoint: "-0%",
        photo1StartPoint: "-0%",
        photo1EndPoint: "-30%",
        photo2StartPoint: "30%",
        photo2EndPoint: "0%",
        title2StartPoint: "-0%",
        title2EndPoint: "50%"
    },
    {
        id: 3,
        title: "Marketing",
        color: "#77D9AE",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690896711/svgviewer-output_28_vtcv2y.svg",
        image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690898751/svgviewer-output_33_pyyfij.svg",
        subtitle: [
            "Social Media Marketing",
            "Search Engine Marketing",
            "Search Engine Optimization",
            "Programmatic Advertising",
            "PPC Advertising"
        ],
        startPoint: "-30%",   // Custom start point for this item
        endPoint: "-0%",     // Custom end point for this item
        hoverOpacity: 1,    // Custom hove
        titleStartPoint: "-30%", // Custom start point for the title
        titleEndPoint: "-0%",
        photo1StartPoint: "-0%",
        photo1EndPoint: "-30%",
        photo2StartPoint: "30%",
        photo2EndPoint: "0%",
        title2StartPoint: "-0%",
        title2EndPoint: "30%"
    },
    {
        id: 4,
        title: "Development",
        color: "#8B35F9",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690896711/svgviewer-output_27_ykeqwh.svg",
        image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690898751/svgviewer-output_34_thfm6e.svg",

        subtitle: [
            "Saas",
            "Web & Ecommerce",
            "Digital Products",
            "App Development",
            "Software Development",
        ],
        startPoint: "-30%",   // Custom start point for this item
        endPoint: "-0%",     // Custom end point for this item
        hoverOpacity: 1,    // Custom hove
        titleStartPoint: "-30%", // Custom start point for the title
        titleEndPoint: "-0%",
        photo1StartPoint: "-0%",
        photo1EndPoint: "-30%",
        photo2StartPoint: "30%",
        photo2EndPoint: "0%",
        title2StartPoint: "20%",
        title2EndPoint: "50%"
    },
    {
        id: 5,
        title: "Consulting",
        color: "#D6395F",
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690896711/svgviewer-output_29_iaxoy1.svg",
        image2: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1690898751/svgviewer-output_35_xsp2oj.svg",
        subtitle: [
            "Growth Strategy",
            "Digitalization",
            "Brand",
            "Digital Trends",
            "Professional Analysis & Reporting",
        ],
        startPoint: "-30%",   // Custom start point for this item
        endPoint: "-0%",     // Custom end point for this item
        hoverOpacity: 1,    // Custom hove
        titleStartPoint: "-30%", // Custom start point for the title
        titleEndPoint: "-0%",
        photo1StartPoint: "-0%",
        photo1EndPoint: "-30%",
        photo2StartPoint: "30%",
        photo2EndPoint: "0%",
        title2StartPoint: "-0%",
        title2EndPoint: "30%"
    },
];
function getDisplayStyles(isHovered, startPoint = "-40%", endPoint = "-20%", hoverOpacity = 1) {
    if (window.innerWidth <= 600) {
        return {
            transform: `translateX(0)`,
            transition: '0',
            opacity: 1,  // Ensures the element is visible
            visibility: 'visible'  // Ensures the element is visible
        };
    }

    if (isHovered) {
        return {
            color: '#fff',
            opacity: hoverOpacity,
            visibility: 'visible',
            transform: `translateX(${endPoint})`,
            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out'
        };
    } else {
        return {
            color: '#000',
            opacity: 0,
            visibility: 'hidden',
            transform: `translateX(${startPoint})`,
            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out'
        };
    }
}

function getExitStyles(isHovered, startPoint, endPoint, hoverOpacity = 1) {
    if (window.innerWidth <= 600) {
        return {
            transform: `translateX(0)`,
            transition: '0',
            opacity: 1,  // Ensures the element is visible
            visibility: 'visible',  // Ensures the element is visible
            zIndex: "0"
        };
    }

    if (isHovered) {
        return {
            color: '#fff',
            opacity: hoverOpacity,
            visibility: 'visible',
            transform: `translateX(${endPoint})`,
            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out',
            zIndex: "1"
        };
    } else {
        return {
            color: '#000',
            opacity: hoverOpacity,
            visibility: 'visible',
            transform: `translateX(${startPoint})`,
            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out',
            zIndex: "0"
        };
    }
}

function Capabilities() {
    const [hoveredId, setHoveredId] = useState(null);

    return (
        <div>
            <div className='mb-4'>
                <span className='titlecapabilities'>
                    Discover how our capabilities<br></br> can improve your business
                </span>
            </div>
            <div className='container'>
                <div className="onlyondesktop">
                <div className='row positioncenter onlyondesktop'>
                    {items.map(item => (
                        <div
                            className='col-12 col-lg-6 hovereffectonthiscomponent px-0'
                            key={item.id}
                            onMouseEnter={() => setHoveredId(item.id)}
                            onMouseLeave={() => setHoveredId(null)}
                        >
                            <Link to={`/services/${item.id}`}>
                                <div className='borderoncomponent py-5'>
                                    <div className='row'>
                                        <div
                                            className='titleofthecapabilities col-12 titelfefe my-2 my-md-0'
                                            style={getDisplayStyles(hoveredId === item.id, item.titleStartPoint, item.titleEndPoint, item.hoverOpacity)}
                                        >
                                            {item.title}
                                        </div>
                                        <div className='col-6'>
                                            <img className='image' src={item.image} alt="imageofcap" style={getExitStyles(hoveredId === item.id, item.photo1StartPoint, item.photo1EndPoint, item.hoverOpacity)} />
                                            <img className='image2' src={item.image2} alt="imageofcap" style={getDisplayStyles(hoveredId === item.id, item.photo2StartPoint, item.photo2EndPoint, item.hoverOpacity)} />
                                        </div>
                                        <div className='col-6 my-auto pe-0 selectorrrr' style={{ textAlign: "left" }}>
                                            <div className='titleofthecapabilities my-2 my-md-0 removethisonhover' style={getExitStyles(hoveredId === item.id, item.title2StartPoint, item.title2EndPoint, item.hoverOpacity)}>
                                                {item.title}
                                            </div>
                                            {item.subtitle.map((sub, index) => (
                                                <div className="component1" key={index} style={getDisplayStyles(hoveredId === item.id, item.startPoint, item.endPoint, item.hoverOpacity)}>
                                                    {sub}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
                </div>
                <div className="onlyonmobile">
                
                <div className='row positioncenter '>
                    {items.map(item => (
                        <div
                            className='col-12 col-lg-6 hovereffectonthiscomponent px-0'
                            key={item.id}
                            onMouseEnter={() => setHoveredId(item.id)}
                            onMouseLeave={() => setHoveredId(null)}
                        >
                            <Link to={`/services/${item.id}`}>
                                <div className='borderoncomponent py-5'>
                                    <div className='row mx-0 '>
                                    <div className='col-6'>
                                            <img className='image' src={item.image} alt="imageofcap" />
                                        </div>
                                        <div className='col-6 my-auto pe-0 selectorrrr' style={{ textAlign: "left" }}>
                                            <div className='titleofthecapabilities my-2 my-md-0 removethisonhover' style={{color: "black"}}>
                                                {item.title}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end pe-3">
                                        <svg width="25" height="25" viewBox="0 0 0.468 0.468" xmlns="http://www.w3.org/2000/svg"><path d="M.258.072a.032.032 0 0 1 .045 0l.14.14a.032.032 0 0 1 0 .045l-.14.14A.032.032 0 0 1 .258.352L.343.265H.047a.032.032 0 0 1 0-.062h.297L.258.117a.032.032 0 0 1 0-.045Z"/></svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </div >
    );
}

export default Capabilities;





