import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import 'swiper/css/autoplay';

import "./Team.css"
import { useLocation } from 'react-router';

import Lorik from '../images/Lorik.png'
import Denis from '../images/Denis.png'
import Bleron from '../images/Bleron.png'


function Team() {
  const [itemData, setItemData] = useState([]);
  const location = useLocation()
  const teamTitle = useRef()
  useEffect(() => {
    // Fetch or update the item data here
    // For demonstration purposes, I'll initialize the data directly
    const itemData = [
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1688391284/kutiza/Team/Gresa_tktn4g.svg",
        position: "UI UX",
        colorposition: "#E92269",
        name: "Gresa Bytyqi",
        fullposition: "UI/UX Designer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1695632750/kutiza/Team/Group_427319548_rs55rs.png",
        position: "Design",
        colorposition: "#35A0DC",
        name: "Dion Arifi",
        fullposition: "Graphic Designer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1695632750/kutiza/Team/Group_427319549_zrsnok.png",
        position: "Front-End",
        colorposition: "#00CF98",
        name: "Mergim Murati",
        fullposition: "Front End Developer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1688391285/kutiza/Team/Diellza_n43t56.svg",
        position: "IOS Dev",
        colorposition: "#E92269",
        name: "Diellza Hoxha",
        fullposition: "iOS Developer",
      },
      {
        image: Lorik,
        position: "Back-End",
        colorposition: "#35A0DC",
        name: "Lorik Aliu",
        fullposition: "Back-End Developer",
      },
      {
        image: "https://res.cloudinary.com/dlvcyd1sr/image/upload/v1708611814/1a8fc5dc8c93201864c9407f9388f49b_cout6q.png",
        position: "IOS Dev",
        colorposition: "#F7D34E",
        name: "Rinesa Grabovci",
        fullposition: "iOS Developer",
      },
    
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1695632750/kutiza/Team/Group_427319555_z93dxv.png",
        position: "Front-End",
        colorposition: "#00CF98",
        name: "Flori Frrokaj",
        fullposition: "Front End Developer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1695632750/kutiza/Team/Group_427319556_zbbbgc.png",
        position: "Back-End",
        colorposition: "#35A0DC",
        name: "Albenit Bytyqi",
        fullposition: "Back-End Developer",
      },
      {
        image: Denis,
        position: "Design",
        colorposition: "#6665DD",
        name: "Denis Demolli",
        fullposition: "Social Media Manager",
      },
      {
        image: Bleron,
        position: "Back-End",
        colorposition: "#6665DD",
        name: "Bleron Maxhuni",
        fullposition: "Back End Developer",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1688391286/kutiza/Team/Drin2_czz4u8.svg",
        position: "Marketing",
        colorposition: "#F7D34E",
        name: "Drin Arifi",
        fullposition: "Digital Marketing Lead",
      },
      {
        image: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1688391296/kutiza/Team/Ardit_eqjz0f.svg",
        position: "Front-End",
        colorposition: "#E92269",
        name: "Ardit Zogjani",
        fullposition: "Front End Developer ",
      },
      
    ];
    setItemData(itemData.slice(0, 5))

    if (location.pathname === '/') {
      teamTitle.current.style.color = '#fff'
    }

  }, [location.pathname]);
  return (
    <div className='container-md px-4 px-lg-5 '>


      <div className="maintittleee servicesTitle pt-0" style={{ color: "black", paddingBottom: "2rem" }}>

        <span className='maintittleee pt-0' id="ourteam" ref={teamTitle}> Meet Our Team Members</span>
      </div>
      <div>
        <Swiper
          grabCursor={true}
          freeMode={true}
          className="mySwiper"
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            370: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },

            450: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            577: {
              slidesPerView: 1.7,
              spaceBetween: 10,
            },
            750: {
              slidesPerView: 1.8,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1400: {
              slidesPerView: 3.6,
              spaceBetween: 30,
            },

          }}
        >
          {itemData.map((item) => (
            <SwiperSlide className='teamswiper' key={item.image}>
              <div className="Figure">
                <div className="displaythis" style={{ background: item.colorposition }}>{item.position}</div>
                <img
                  className="imagehover sizeindifferentscreenss"
                  src={item.image}
                  alt="Team Member"
                />
                <div className="displaythis1">
                  <div className="theorderof2">
                    <p className="nameofteammember" style={{ color: item.colorposition }}>{item.name}</p>
                    <div className="" style={{ display: "flex" }}>
                      <p className="positionofteammember mb-0" style={{ fontWeight: "700" }}>{item.fullposition}</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    </div>
  )
}

export default Team