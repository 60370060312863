import React from 'react'
import Whychoose from "../images/whychoose.svg"

function Whyus() {
    return (
        <div className='container-md px-4 px-lg-5 containerofspace'>
            <div>
                <div className='row text-start align-items-center'>
                    <div className='col-12 col-md-6'>
                        <div className='whyflex'>
                        <span className='titleofchoose text-center text-md'>  Long-Term   <br/>  <span className='subtitleofchoose'> Partnerships</span>   and <br/> Ongoing Support</span>
                        <div className='px-1 mt-3 whysubtitleheading pb-4' style={{borderBottom: '2px solid #000',}}>
                            <span className='subtitleofwho subttileheading'>
                            At Kutiza, we don't just complete projects; we aim to build long-term partnerships. We are committed to your ongoing success and growth. Even after project completion, we provide ongoing support, monitor performance, and adapt strategies to keep your digital presence thriving
                            </span>

                        </div>
                        <div className='' style={{  width: "fit-content" }} >
                            <img className='img-fluid mt-4  pb-5 pb-md-0' style={{maxHeight: "300px"}} src={Whychoose} alt="" />
                        </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='boxx mt-2'>
                            <div>
                                <span className='titleofwho'>
                                Creative Solutions that Captivate
                                </span>
                            </div>
                            <div className='pt-2'>
                                <span className='subtitleofwho'>
                                At Kutiza, we believe that creativity is the key to capturing the hearts and minds of your audience. Our team of talented designers and creatives are masters of their craft, bringing your brand to life with visually stunning designs, captivating storytelling, and immersive experiences.
                                </span>
                            </div>
                        </div>
                        <div className='mt-3 pt-2'>
                            <div className='boxx'>
                                <div>
                                    <span className='titleofwho'>
                                    Development Expertise that Empowers
                                    </span>
                                </div>
                                <div className='pt-2'>
                                    <span className='subtitleofwho'>
                                    In the ever-evolving digital landscape, having a robust and user-friendly online presence is paramount. Our team of skilled developers and technologists at Kutiza have the expertise to build powerful and scalable digital solutions tailored to your specific needs. 
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 pt-2'>
                            <div className='boxx'>
                                <div>
                                    <span className='titleofwho'>
                                    Strategic Marketing for Success
                                    </span>
                                </div>
                                <div className='pt-2'>
                                    <span className='subtitleofwho'>
                                    In a crowded digital marketplace, standing out and reaching your target audience requires a well-crafted marketing strategy. At Kutiza, we combine data-driven insights with creative thinking to develop strategic marketing campaigns that drive results. 
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Whyus