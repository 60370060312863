
import 'animate.css';
import './LandingComponent.css'
// import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import "swiper/css";
// import { useEffect, useState } from 'react';
// import { Autoplay } from "swiper";
import 'swiper/css/effect-fade';
// import { EffectFade } from 'swiper';
// import {ReactComponent as Logo} from ''
// import img4 from '../LandingPage/images/img4.png'
import Landing from './images/thirdimage.webp'
// import Landing from './images/firstimage.png'
// import Boxsvg from './images/Box.svg'
import Dashsvg from './images/Dash.svg'

import Boxsvg1 from './images/svg1.svg'
import Boxsvg2 from './images/svg2.svg'
import Boxsvg3 from './images/svg3.svg'
import React from 'react';
import { Link } from 'react-router-dom';

const items = [
    {
        id: 1,
        title: 'Branding',
        subtitle: 'Optimise your add customers',
        image: Boxsvg1
    },
    {
        id: 2,
        title: "",
        subtitle: "",
        image: Dashsvg
    },
    {
        id: 3,
        title: 'Development',
        subtitle: 'Optimise your add customers',
        image: Boxsvg2
    },
    {
        id: 4,
        title: "",
        subtitle: "",
        image: Dashsvg
    },
    {
        id: 5,
        title: 'Design',
        subtitle: 'Optimise your add customers',
        image: Boxsvg3
    }
]



function LandingComponent() {
    return (
        <>

            <div className="container-md px-4 px-lg-5">

                <div className="d-flex align-items-center  containerofspace navbarGap" style={{ textAlign: '-webkit-center', alignItems: 'center', justifyContent: "center", alignContent: 'center' }} >
                    <div className="row gx-0 text-start mb-5 mb-lg-0">
                        <div className="title222 slide1animationtext pt-4 col-12 col-lg-6 mx-0" >
                            <p className="titleoflandingcomponent">Elevate Your Digital Presence </p>
                            <p className="subtitleofheader">Crafting Experiences That Captivate, Connect, and Convert</p>
                            <Link to={'/contactus'} className='buttonoflanding px-4 fontofbuttonss my-2 d-block' style={{ color: "#000", borderRadius: "0px" }}>
                                Work with us
                            </Link>
                        </div>
                        <div className="col-12 col-lg-6 photoandsvgtabletandup px-0 mt-2 mt-sm-5 pb-0 mx-0 minuscommand ps-0 ps-lg-4" >
                            <img src={Landing} style={{ width: "100%" }} alt='' />
                        </div>
                    </div>
                </div >
            </div>
            <div className="containerofspace blackWrapper" >
                <div className="container">
                    <div className='row pb-5'>
                        <div className=' col-12 col-lg-6'>
                            <div className='row mb-5 mb-md-1'>
                                {items.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <div className='offset-1 offset-md-2 col-4 '>
                                            <img src={item.image} alt='' />
                                        </div><div className='col-6 my-auto'>
                                            <div className='text-start d-flex flex-column justify-content-evenly ' style={{ color: "white" }}>
                                                <div className='contactustitle'>{item.title}</div>
                                                <div>{item.subtitle}</div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className=' col-12 col-lg-6 my-auto ' >
                            <div className=" " style={{ color: "white !important" }}>

                                <p className="firstlandingtitle text-xxl-start"> WHAT WE DO</p>
                                <p className="landingtitle text-xxl-start"> We are a Digital Agency & more</p>
                                <p className="landingsubtitle  text-xxl-start digitalheadingstyle mt-3 mb-xxl-0">At Kutiza, we specialize in providing a wide range of digital solutions to help businesses thrive in the online world. With our expertise and passion for innovation, we empower our clients to unlock their digital potential and achieve remarkable success. From captivating web design and development to strategic digital marketing, search engine optimization, and beyond, our comprehensive suite of services is tailored to meet your unique needs. </p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row align-content-center mx-auto socialcontainer">
                        <div className="col-12 col-xxl justift-content-end">
                            <p className="thisiscontacttitle text-xxl-start"> Creating Digital Possibilities</p>
                            <p className="digitalheadingtext  text-xxl-start digitalheadingstyle mt-3 mb-xxl-0">At Kutiza, we specialize in providing a wide range of digital solutions to help businesses thrive in the online world. With our expertise and passion for innovation, we empower our clients to unlock their digital potential and achieve remarkable success. From captivating web design and development to strategic digital marketing, search engine optimization, and beyond, our comprehensive suite of services is tailored to meet your unique needs. </p>
                        </div>
                        <div className="col-12 col-xxl  text-xxl-start">
                            <img className="img-fluid imgdigital" src={img4} alt='' />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )

}

export default LandingComponent