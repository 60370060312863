import React from 'react'
import './Companions.css'
const items = [
    {
        id: 1,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692975375/kutiza/companions/Group_427319531_mpkgir.png"

    },
    {
        id: 2,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694011852/kutiza/companions/svgviewer-output_36_wgdnu2.svg"


    },
    {
        id: 3,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694011853/kutiza/companions/svgviewer-output_37_fvadpn.svg"
    },
    {
        id: 4,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1694012039/kutiza/companions/svgviewer-output_38_xlulqz.svg"

    },
    {
        id: 5,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692975375/kutiza/companions/Group_427319532_p6uwsr.png"

    },
    {
        id: 6,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692975376/kutiza/companions/Group_427319527_odtckh.png"
        
    },
    {
        id: 7,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692975376/kutiza/companions/Group_427319536_sxgpil.png"
        
    },
    {
        id: 8,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692975376/kutiza/companions/Group_427319534_fa76lh.png"

    },
    {
        id: 9,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692975376/kutiza/companions/Group_427319535_jxyz7v.png"
    },
    {
        id: 10,
        link: "https://res.cloudinary.com/dr5bzig6k/image/upload/v1692975375/kutiza/companions/Group_427319528_pcbhkt.png"
    }
];

function Companions() {
    return (
        <div>
            <div className='container-md px-4 px-lg-5  my-5 py-5'>
                <p className='text-start titleofgridd'>
                Who we have worked <br></br> with
                </p>
                <div className="grid-container px-0">
                    {items.map(item => (
                        <div key={item.id} className="grid-item">
                            <img src={item.link} alt={`Companion ${item.id}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Companions;