// import servicesdata from "./ServicesData.js";
import './service.css'
// import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import "swiper/css";
// import { Autoplay } from "swiper";
import 'swiper/css/effect-fade';
// import { EffectFade } from 'swiper';
import svg1 from '../Services/imgs/services2.svg'

function OurServices() {

    return (
        <div className="container">
            <div className='row align-content-center mx-auto socialcontainer' >
                <div className='col-12 col-lg  text-xl-start order-2 order-lg-1'>
                    <img style={{ maxHeight: "403px" }} className='img-fluid' src={svg1} alt='' />
                </div>
                <div className='col-12 col-lg my-auto justift-content-end order-1 order-lg-2'>
                    <p className='servicestoptitle text-xl-start mt-3'>Development Solutions that Empower</p>
                    <p className=' subheadingtitle text-xl-start socialheadingstyle' >Our development services bring your digital vision to life. We craft functional, user-friendly websites and web applications that engage and convert visitors. Our expertise covers front-end and back-end development, responsive design, e-commerce solutions, CMS integration, and custom web development.
                    </p>
                </div>


            </div>
        </div>
    )
}

export default OurServices
