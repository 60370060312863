import React, { useEffect, useState } from 'react';
import image1about from "../images/about/imageoneabout.svg"
import image2about from "../images/about/imagetwoabout.svg"
import "./Stories.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from 'swiper';

function Stories() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {

            setScreenWidth(window.innerWidth);

        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    return (
        <div className='container-md px-4 px-lg-5 containerofspace'>
            <p className='storiestitle mb-5 mb-md-0  px-md-0 pb-md-5'>
                The story behind how our <br></br>
                company was founded
            </p>
            {(screenWidth <= 767) && (
                <div>
                    <Swiper style={{
                        "--swiper-pagination-color": "#fff",
                        "--swiper-pagination-bullet-inactive-color": "rgba(255,255,255,0.99)",
                        "--swiper-pagination": "marginTop:100vh",
                    }}
                    
                        pagination={{
                            dynamicBullets: true,
                        }}
                        modules={[Pagination]}
                        slidesPerView={1}
                        spaceBetween={10}

                        breakpoints={{
                            240: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },

                        }}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className=''>
                                <img className='img-fluid' src={image1about} alt='' />
                            </div>
                            <div className='' style={{ backgroundColor: "#FDC93F", height: "440px" }}>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='mt-4 px-3'>
                                            <p className='px-lg-5 text-start subtitlestories subbtitlestories'>
                                                A Collective of Digital Enthusiasts
                                            </p>
                                            <p className=' text-start subheadingofstories subbheadingstories mt-4  mb-4 '>
                                                At Kutiza, we are proud to have a diverse and passionate team of digital enthusiasts. Each member of our team brings unique expertise, perspectives, and skills to the table. From talented designers and developers to strategic marketers and creative thinkers, our team is united by a shared dedication to delivering exceptional results for our clients.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className=''>
                                <img className='img-fluid ' src={image2about} alt='' />
                            </div>
                            <div className='' style={{ backgroundColor: "#6665DD", height: "440px" }}>
                                <div className='row g-0'>
                                    <div className='col'>
                                        <div className='mt-3 px-3' >
                                            <p className=' px-lg-5 text-start subtitlestories subbtitlestories mt-4 mt-lg-0 '>
                                                Nurturing Innovation and Collaboration
                                            </p>
                                            <p className='text-start subheadingofstories subbheadingstories mt-4 mb-4 ' >
                                                At Kutiza, our company culture is built on fostering innovation and collaboration. We believe that the best ideas come from open dialogue, diverse perspectives, and a supportive work environment. Our team members are encouraged to think outside the box, embrace creativity, and challenge the status quo. We promote a culture of collaboration, where everyone's voice is valued, and ideas are welcome.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            )}

            {(screenWidth >= 767) && (
                <div className='row gx-0'>
                    <div className='col-12 col-lg-6 ' style={{ backgroundColor: "#FDC93F" }}>
                        <div className='row'>
                            <div className='col'>
                                <div className='mt-5 px-4 mt-lg-2 px-lg-0 mb-auto mt-lg-0 pb-lg-0 pb-xl-0 mt-xl-5 px-xl-4 mt-xxl-5 px-xxl-5 pt-xxl-5'>
                                    <p className='ps-lg-4 pe-lg-1  text-start subtitlestories subbtitlestories'>
                                        A Collective of Digital Enthusiasts
                                    </p>
                                    <p className='ps-lg-4 pe-lg-1  text-start subheadingofstories subbheadingstories  mb-4 '>
                                        At Kutiza, we are proud to have a diverse and passionate team of digital enthusiasts. Each member of our team brings unique expertise, perspectives, and skills to the table. From talented designers and developers to strategic marketers and creative thinkers, our team is united by a shared dedication to delivering exceptional results for our clients.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 '>
                        <img className='img-fluid' src={image1about} alt='' />
                    </div>
                    <div className='row  g-0'>
                        <div className='col-12 col-lg-6'>
                            <img className='img-fluid ' src={image2about} alt='' />
                        </div>
                        <div className='col-12 col-lg-6' style={{ backgroundColor: "#6665DD" }}>
                            <div className='row g-0'>
                                <div className='col'>
                                    <div className='mt-5 px-4 mt-lg-2 px-lg-0 mb-auto mt-lg-0 pb-lg-0 pb-xl-0 mt-xl-4 px-xl-4 mt-xxl-4 px-xxl-5 pt-xxl-5' >
                                        <p className='ps-lg-4 pe-lg-1  text-start subtitlestories subbtitlestories mt-4 mt-lg-0 '>
                                            Nurturing Innovation and Collaboration
                                        </p>
                                        <p className='ps-lg-3 pe-lg-1  px-xl-4 text-start subheadingofstories subbheadingstories mt-4 mb-4 mt-lg-0 mb-lg-0 ' >
                                            At Kutiza, our company culture is built on fostering innovation and collaboration. We believe that the best ideas come from open dialogue, diverse perspectives, and a supportive work environment. Our team members are encouraged to think outside the box, embrace creativity, and challenge the status quo. We promote a culture of collaboration, where everyone's voice is valued, and ideas are welcome.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Stories