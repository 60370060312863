import React from "react";
import { useParams } from "react-router-dom";
import {itemsdata} from "./itemsdata";

function PartnerDetails() {

    const { partners } = useParams();
    const itemId = parseInt(partners);
    const item = itemsdata.find((item) => item.id === itemId);

    return (
        <div className="selectioncolor" style={{ background: 'black' }}>
            <div className='container-md px-4 px-lg-5 pb-5'>
                <div className=" pt-5 ">
                    <div className="gx-4 gx-lg-5">
                        <div className="containerofspace order-2 order-sm-1">
                            <div className="maintittleee servicesTitle pt-0 text-start" style={{ color: "black", paddingBottom: "2rem" }}>
                                <span className='maintittleee selectioncolor' style={{ color: '#fff' }}>{item.title}</span>
                            </div>
                            <div className="text-start " style={{ color: '#fff' }}>
                                <p className="subtitleoflandport mb-0 selectioncolor">
                                    {item.description}
                                </p>
                                <div className="col text-center order-1 order-sm-2 pt-5 pt-sm-2">
                                    <img style={{ maxHeight: "510px", maxWidth: "100%" }} src={item.image} alt="" className="" />
                                </div>
                                <div className="mt-3 " style={{ color: '#fff' }}>
                                    <p className="subtitleoflandport mb-0 selectioncolor"> {item.subtitle}
                                    </p> </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerDetails;
