import React from 'react';
import { Link } from "react-router-dom";
import "./LandingPortfolio.css"
import { itemsdata } from './itemsdata'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';


const LandingPortfolio2 = () => {
    const [swiperInstance, setSwiperInstance] = useState(0)
    return (
        <>
            <div className="container-md px-4 px-lg-5 my-5 pt-5">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1.1}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    breakpoints={{
                        640: {
                            slidesPerView: 1.7,
                            spaceBetween: 20,
                        },
                        900: {
                            slidesPerView: 2.1,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 2.2,
                            spaceBetween: 30,
                        },
                    }}
                >
                    {
                        itemsdata.map((item) =>
                            <SwiperSlide>
                                {
                                    item.id !== 1
                                        ?
                                        <Link to={`/portfolio/${item.id}`}>
                                            <img src={item.image} alt="" className='w-100' style={{ maxWidth: '600px' }} />
                                        </Link>

                                        :
                                        <>
                                            <div className='text-start mb-auto'>
                                                <p className='maintitleofslider mb-0'>{item.maintitle}</p>
                                                <p className='titleofgridd py-2' style={{color: '#000'}}>{item.sidetitle}</p>
                                                <Link to={`/portfolio`} className='buttonoflanding px-5 fontofbuttonss' style={{ color: "BLACK", borderRadius: "0px",display: 'block' }}>View All</Link>
                                            </div>
                                        </>
                                }

                            </SwiperSlide>

                        )
                    }
                </Swiper>

            </div>
            <button className='buttononslider mx-3 p-3' onClick={() => { swiperInstance.slideTo(swiperInstance.activeIndex - 1, undefined, false) }}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.5303 4.21967C18.8232 4.51256 18.8232 4.98744 18.5303 5.28033L9.81066 14L18.5303 22.7197C18.8232 23.0126 18.8232 23.4874 18.5303 23.7803C18.2374 24.0732 17.7626 24.0732 17.4697 23.7803L8.21967 14.5303C7.92678 14.2374 7.92678 13.7626 8.21967 13.4697L17.4697 4.21967C17.7626 3.92678 18.2374 3.92678 18.5303 4.21967Z" fill="black" />
                </svg>

            </button>
            <button className='buttononslider mx-3 p-3' onClick={() => { swiperInstance.slideTo(swiperInstance.activeIndex + 1, undefined, false) }}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.46967 4.21967C9.17678 4.51256 9.17678 4.98744 9.46967 5.28033L18.1893 14L9.46967 22.7197C9.17678 23.0126 9.17678 23.4874 9.46967 23.7803C9.76256 24.0732 10.2374 24.0732 10.5303 23.7803L19.7803 14.5303C20.0732 14.2374 20.0732 13.7626 19.7803 13.4697L10.5303 4.21967C10.2374 3.92678 9.76256 3.92678 9.46967 4.21967Z" fill="black" />
                </svg>
            </button>
        </>
    )
}

export default LandingPortfolio2;