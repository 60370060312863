import React from "react";
import { useParams } from "react-router-dom";
import DataofTabs from "./DataofTabs";
import './DataofTabs.css'
const DetailofPortfolio = () => {
  const { id } = useParams();
  const itemId = parseInt(id);
  const item = DataofTabs.find((item) => item.id === itemId);


  if (!item) {
    return <div>Item not found</div>;
  }

  return (
    <div className="mt-5 container-md px-4 ">
      <h1 className="titledetilport pt-5 pb-3">{item.title}</h1>
      <p className="subtitledetailport"> {item.description}</p>
      {/* <img src={item.img} alt={item.title} /> */}
      <div className="pt-4">
        <div className="blackbox my-5" >
          <div className="p-5" >
            <div className="borderofbox" style={{position: "relative"}}>
              <div className="imageboxbackground1"></div>
              <div className="imageboxbackground2"></div>
              <div className="imageboxbackground3"></div>
              <div className="imageboxbackground4"></div>
              <div className="py-5">

            <p className="titleofproblem">
              {item.problem}
            </p>
            <p className="solutiontitle">
              {item.solution}
            </p>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5">
          <p className="bulletpointbusiness text-start">
            Business Challenges
          </p>

          {item.challanges.map((sub, index) => (
            <div className="d-flex flex-row my-auto py-3 ">
              <span className="pe-3 my-auto">
                <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.400391" width="34" height="34" fill="#878787" fill-opacity="0.2" />
                </svg>
              </span>
              <p key={index} className="linesofbussiness text-start my-auto">
                {sub}
              </p>
            </div>
          ))}

        </div>
        <div className="pt-5">
        <p className="bulletpointbusiness text-center">
        Final Results
          </p>

          <div className="row">
          {item.images.map((image, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 pb-4">
                <img className="img-fluid" src={image} alt="" />
            </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailofPortfolio;
