import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";
// import { Button } from 'react-bootstrap';
// import { ChevronDown } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
// import logo from "./images/logo.svg"
import { useLocation } from 'react-router';
import MenuToggle from './NavBar/MenuToggle';
function Header({ isOpen, toggleOpen }) {

    const [show, setShow] = useState(false)

    const controlNavbar = () => {
        if (window.scrollY > 30) {
            setShow(true)
        } else {
            setShow(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, [])

    const [IsInContact, setIsInContact] = useState(false)
    const [IsInServices, setIsInServices] = useState(false)
    
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/contactus' || location.pathname.includes('/services/')) {
            setIsInContact(true)
        }
        else {
            setIsInContact(false)
        }

    }, [location])

    useEffect(() => {
        if( location.pathname.includes('/services/')) {
            setIsInServices(true)
        } else {
            setIsInServices(false)
        }
    }, [location])

    useEffect(() => {
        if (isOpen) {
            toggleOpen()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


    return (
        <div className={`${isOpen ? 'openNavBarStyle' : 'zindexTransitionWrapper'}`}>
            <div className=' fixed-top justify-content-center zindexTransition' style={{ zIndex: isOpen ? 12200 : '1030' }}>
                <div className={`nav-container px-0 ${(IsInContact) && 'contactNavTransition'} ${(IsInContact && !isOpen) && 'navBgcontact'} ${(IsInServices) && "servicesdetailpage"} ${show && 'navBg'}`} style={{ background: isOpen ? 'transparent' : '' }}>
                    <div className='container-md g-0 px-4 px-lg-5 '>
                        <div className='row g-0 justify-content-between align-items-center'>
                            <div className='navLogo col-auto'>
                                <Link to='/' className="" style={{ position: "relative" }}>
                                    <span style={{ width: '85px', zIndex: 101 }} className='logoofourcompany '>
                                        <svg width="85px" height="45" viewBox="0 0 114 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M41.343 18.41v21.117H34.8v-7.481l-3.277 3.301-2.941 2.953-1.68 1.7-4.62-4.653V18.409h6.532v14.209l1.892 1.908 4.094-4.118V18.41h6.543Zm-22.276 4.663v16.48H12.54V25.802l-1.897-1.908-4.109 4.143v11.516H0V0h6.533v26.39l3.293-3.317.842-.838 3.789-3.8 3.778-3.81.817.82-3.779 3.811 3.794 3.817Zm46.846 12.269L61.293 40l-4.621-4.658v-16.47h6.543v13.756l2.698 2.714ZM63.21 6.229h-6.538v6.57h6.538V6.23ZM51.168 16.424v16.194l2.709 2.73L49.256 40l-4.621-4.653V16.423h-3.272V15.26h3.272V6.23h6.533v9.03h3.277v1.165h-3.277Zm38.444 17.727-2.667 5.392H68.891V34.9l10.534-11.812H73.29l-1.701 3.431-5.37-2.682 2.672-5.397h18.054v4.648L76.417 34.9h6.135l1.7-3.431 5.36 2.682Zm23.565.754-4.621 4.648-4.631-4.648v-3.312l-6.678 6.717L95.562 40l-4.62-4.658h.01v-7.013h6.533v4.3l1.892 1.902 4.548-4.58v-4.144l-.78-.785H90.952V18.44h14.891l4.62 4.648v9.093l2.714 2.724Z" fill="black" /></svg>
                                    </span>
                                </Link>
                            </div>
                            <div className='col-auto my-auto'>
                                <MenuToggle toggle={() => toggleOpen()} isOpen={isOpen} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header